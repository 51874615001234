import React, { useState, useEffect } from "react";
import { db } from "../../../Config/FirebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";

const CertificateVerify = () => {
  //variable

  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState(null);
  const [filteredCertificateData, setFilteredCertificateData] = useState(null);

  function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  }

  const fetchCertificateInfo = async (certificateId) => {
    const certificateDataRef = collection(db, "certificateData");

    // Create a query that selects documents where certificate_id matches the entered certificateId
    const q = query(
      certificateDataRef,
      where("certificade_id", "==", certificateId.toUpperCase())
    );

    try {
      const querySnapshot = await getDocs(q);
      const certificatesData = [];

      querySnapshot.forEach((doc) => {
        certificatesData.push({ id: doc.id, ...doc.data() });
      });

      setCertificateData(certificatesData);
    } catch (error) {
      console.error("Error fetching certificate data: ", error);
    }
  };

  const handleFetched = () => {
    fetchCertificateInfo(certificateId);
    setCertificateId("");
  };

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center ">
        <div className="card mt-5" style={{ width: "22rem" }}>
          <div className="card-body">
            <h5 className="card-title">Certificate Information</h5>
            <div className="form-group">
              <label htmlFor="certificateId">
                Certificate ID (eg:MKIN23NI01)
              </label>
              <input
                type="text"
                className="form-control"
                id="certificateId"
                value={certificateId}
                onChange={(e) => setCertificateId(e.target.value)}
              />
            </div>
            <div className="text-center">
              <button
                className="btn btn-success btn-block mt-2"
                onClick={handleFetched}
              >
                Fetch
              </button>
            </div>

            {certificateData ? (
              <div className="mt-3">
                <p>
                  <strong>Certificate ID:</strong>{" "}
                  {certificateData[0]?.certificade_id?.toUpperCase()}
                </p>
                <p>
                  <strong>Name:</strong>{" "}
                  {certificateData[0]?.name?.toUpperCase()}
                </p>
                <p>
                  <strong>College:</strong>{" "}
                  {certificateData[0]?.college?.toUpperCase()}
                </p>
                <p>
                  <strong>Internship In Field:</strong>{" "}
                  {certificateData[0]?.intern_field?.toUpperCase()}
                </p>
                <p>
                  <strong>Duration (In Month):</strong>{" "}
                  {certificateData[0]?.duration}
                </p>
                <p>
                  <strong>From:</strong> {formatDate(certificateData[0]?.from)}{" "}
                  to {formatDate(certificateData[0]?.to)}
                </p>
              </div>
            ) : (
              <p className="text-danger text-center mt-2">
                <b>No data found</b>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateVerify;
