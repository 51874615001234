import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
// import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookMessenger,FaWhatsapp } from "react-icons/fa6";
import { BsEmojiSmileFill } from "react-icons/bs";
const NeedHelpIcon = () => {
  const [contactModalVisible, setContactModalVisible] = useState(false);

  const whatsappNumber = "+918809777798"; // Your WhatsApp number
  const whatsappMessage = "Hello, I need help! in searching tutor"; // Default message to send
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    whatsappMessage
  )}`;

  // Existing useEffect and functions

  const showContactModal = () => {
    setContactModalVisible(true);
  };

  const handleContactModalCancel = () => {
    setContactModalVisible(false);
  };

  return (
    <div>
      {" "}
      <Tooltip title="Need help?">
        <Button
          type="primary"
          shape="circle"
          icon={<FaFacebookMessenger />}
          onClick={showContactModal}
          style={{
            position: "fixed",
            right: "20px",
            top: "60%",
            transform: "translateY(-50%)", // Center vertically
            zIndex: 1000,
            color: "yellow",
          }}
        />
      </Tooltip>
      <Modal
        title="Need Help?"
        visible={contactModalVisible}
        onCancel={handleContactModalCancel}
        footer={[
          <Button key="close" onClick={handleContactModalCancel}>
            Close
          </Button>,
        ]}
      >
        <div style={{ padding: "20px", color: "green", textAlign: "center" }}>
          <h4>We are here to help you! <BsEmojiSmileFill/></h4>
          <p>
            <a
              href={`tel:${whatsappNumber}`}
              style={{
                color: "#000",
                textDecoration: "none",
                fontSize: "1rem",
              }}
            >
              <b>8809-7777-98</b>{" "}
              {/* Assuming this is the phone number you want to display */}
            </a>
          </p>
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center", // This will center the flex items
              alignItems: "center",
              color: "green",
              textDecoration: "none",
            }}
          >
            <FaWhatsapp size={30} />
            <span style={{ marginLeft: "10px" }}>Chat with us on WhatsApp</span>
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default NeedHelpIcon;
