import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Form,
  Input,
  Radio,
  Select,
  Button,
  notification,
  Tooltip,
  Popconfirm,
  Typography,
} from "antd";
import { FaFacebookMessenger, FaWhatsapp } from "react-icons/fa6";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Footer from "../../V1/Footer-Comp/Footer";
import Navbar from "../../V1/Home-Comp/Navbar";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../Config/url";
import { auth } from "../../../Config/FirebaseConfig";
import { db } from "../../../Config/FirebaseConfig";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { TeachingCity } from "../../V2/Tutor-Profile/Dashboard-Tutor/Constant";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
const RegisterForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [cityName, setCityName] = useState("");
  const [accountType, setAccountType] = useState("Tutor");
  const [gender, setGender] = useState("Male");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleRegistration = async (values) => {
    const {
      name,
      email,
      password,
      contactNumber,
      whatsAppNo,
      cityName,
      accountType,
      gender,
    } = values;

    // e.preventDefault();
    setLoading(true);

    try {
      // Create a new user account with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth, // Use 'auth' from your Firebase configuration
        email,
        password
      );

      const user = userCredential.user;

      // Send email verification link to the user's email address
      await sendEmailVerification(user);

      // Set the user's additional information in the Firestore database
      await setDoc(doc(db, "users", user.uid), {
        fullname: name,
        contactNumber: contactNumber,
        whatsAppNo: whatsAppNo,
        accountType: accountType,
        gender: gender,
        createdAt: serverTimestamp(),
        email: email,
        teachingCity: cityName,
      });

      // Show a success message

      notification.success({
        message: "Registration Successful",
        description:
          "Please verify your email. A verification email has been sent.",
      });

      // Automatically redirect to the sign-in page after 3 seconds
      setTimeout(() => {
        history("/sign-in");
      }, 3000); // 3000 milliseconds = 3 seconds
    } catch (error) {
      // Handle errors here and set custom error messages
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error code:", errorCode);
      console.error("Error message:", errorMessage);
      setErrorMessage(errorMessage);
    }

    setLoading(true);
  };

  const cardStyle = {
    maxWidth: "300px",
    margin: "0 auto",
    marginTop: "5rem",
  };

  const buttonStyle = {
    width: "100%",
    cursor: "pointer",
    marginTop: "5px",
  };
  const successMessageStyle = {
    backgroundColor: "#4CAF50", // Green background color
    color: "white", // White text color
    padding: "10px", // Padding around the message
    borderRadius: "5px", // Rounded corners
    marginTop: "10px", // Spacing from the button
  };

  return (
    <>
      <Helmet>
        <title>Register | Mobikraft Learning</title>
        <meta
          name="description"
          content="Discover and connect with certified tutors from top cities like Deoghar, Bhagalpur, Ranchi, Jamui, and Patna. Find a tutor for Math, English, Biology, Physics, and more."
        />
        <meta
          name="keywords"
          content="Home Tuition, Home Tutor, Best Teacher, class 9, class 10, class 11, class 12, Tutors, Deoghar, Bhagalpur, Ranchi, Jamui, Patna, Math, English, Biology, Physics"
        />
      </Helmet>

      <Navbar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card shadow" style={cardStyle}>
              <div className="card-body text-center m-4">
                <h5>Register</h5>
                <p>
                  For Free <b> &#x20b9; 0</b>
                </p>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>

              <Form
                form={form}
                onFinish={handleRegistration}
                layout="vertical"
                className="px-4 pb-4 pt-0"
              >
                <Form.Item
                  name="name"
                  label="Full Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    { required: true, message: "Please select your gender!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="cityName"
                  label="City Name"
                  rules={[
                    { required: true, message: "Please select your city!" },
                  ]}
                >
                  <Select placeholder="Select City">
                    {TeachingCity.map((city) => (
                      <Select.Option key={city} value={city}>
                        {city}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="contactNumber"
                  label="Contact No."
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact number!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Contact No must be 10 digits!",
                    },
                  ]}
                >
                  <Input type="tel" maxLength={10} />
                </Form.Item>

                <Form.Item
                  name="whatsAppNo"
                  label={<>WhatsApp No.</>}
                  rules={[
                    {
                      required: true,
                      message: "Please input your WhatsApp number!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "WhatsApp No must be 10 digits!",
                    },
                  ]}
                >
                  <Input type="tel" maxLength={10} />
                </Form.Item>

                <Form.Item
                  name="accountType"
                  label="Are You ?"
                  rules={[
                    { required: true, message: "Please select account type!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Student">Student</Radio>
                    <Radio value="Tutor">Tutor</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="email"
                  label={
                    <>
                      Email
                      <Typography.Text
                        type="secondary"
                        style={{
                          display: "block",
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        A Verification Link Will Be Sent On This
                      </Typography.Text>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Create Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <p>
                    By registering here you agree to our{" "}
                    <a href="/terms-and-condition">Terms & Conditions</a>.
                  </p>

                  <Button type="primary" htmlType="button">
                    <Popconfirm
                      title="Please check your email. A verification link will be sent."
                      onConfirm={() => form.submit()}
                      okText="Register"
                      cancelText="Cancel"
                    >
                      Register
                    </Popconfirm>
                  </Button>
                </Form.Item>
                <div className="form-group mt-3">
                  <Link to="/sign-in">Already a User!</Link>
                </div>
              </Form>

              {successMessage && (
                <div className="success-message" style={successMessageStyle}>
                  {successMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForm;
