import React from "react";
import { Typography } from "antd";
import styles from "./termAndCondition.module.css";
import Navbar from "../../Components/V1/Home-Comp/Navbar";
import Footer from "../../Components/V1/Footer-Comp/Footer";

const { Title, Paragraph } = Typography;

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div className={styles.termsContainer}>
        <Title level={2}>Terms and Conditions for Mobikraft Learning</Title>
        <Paragraph>Last Updated: [20-Mar-2024]</Paragraph>

        <Title level={3}>Services</Title>
        <Paragraph>
          Mobikraft Learning provides a platform that connects students with
          verified home tutors. We facilitate the discovery and engagement of
          tutors for various subjects and educational levels.
        </Paragraph>

        <Title level={3}>User Accounts</Title>
        <Paragraph>
          Users may need to register and create an account to access certain
          features of the site. You agree to provide accurate and complete
          information during the registration process. You are responsible for
          maintaining the confidentiality of your account credentials and for
          all activities that occur under your account.
        </Paragraph>

        <Title level={3}> Use of the Platform</Title>
        <Paragraph>
          You agree to use the Mobikraft Learning platform only for lawful
          purposes and in a way that does not infringe the rights of or restrict
          or inhibit anyone else's use and enjoyment of the website.
        </Paragraph>

        <Title level={3}>Intellectual Property Rights</Title>
        <Paragraph>
          The content on Mobikraft Learning, including text, graphics, images,
          and information obtained from our licensors, is protected by
          copyright, trademarks, and other intellectual property laws. No
          content from this site may be copied, reproduced, republished,
          uploaded, posted, transmitted, or distributed in any way without our
          express written permission.
        </Paragraph>

        <Title level={3}> Links to Third-Party Websites</Title>
        <Paragraph>
          Our website may contain links to third-party websites. These links are
          provided for convenience only, and we do not endorse, warrant, or
          guarantee the products, services, or information described or offered
          at these other websites.
        </Paragraph>

        <Title level={3}> Disclaimer of Warranties</Title>
        <Paragraph>
          The site and its content are provided "as is" without warranty of any
          kind, either express or implied. While Mobikraft Learning strives to
          provide accurate and up-to-date information, we make no warranties or
          representations as to the accuracy, reliability, completeness, or
          timeliness of the content.
        </Paragraph>

        <Title level={3}> Limitation of Liability</Title>
        <Paragraph>
          To the fullest extent permitted by law, Mobikraft Learning, its
          affiliates, and their respective officers, directors, employees,
          agents, and licensors are not liable for any direct, indirect,
          punitive, incidental, special, consequential, or exemplary damages
          arising out of or in connection with the website or these terms.
        </Paragraph>

        <Title level={3}>Indemnification</Title>
        <Paragraph>
          You agree to defend, indemnify, and hold harmless Mobikraft Learning
          and its affiliates from and against any claims, liabilities, damages,
          losses, and expenses arising from or in any way related to your use of
          the website or your violation of these Terms.
        </Paragraph>

        <Title level={3}> Changes to Terms</Title>
        <Paragraph>
          We reserve the right to modify these Terms at any time. Your continued
          use of the website after such changes have been posted indicates your
          acceptance of the new Terms.
        </Paragraph>

        <Title level={3}> Governing Law</Title>
        <Paragraph>
          These Terms shall be governed by and construed in accordance with the
          laws of  Information Technology Act, 2000, without giving effect to any principles
          of conflicts of law.
        </Paragraph>

        <Title level={3}>Contact Us</Title>
        <Paragraph>
          If you have any questions about these Terms, please contact us at
          [<b>mobikraftindia@gmail.com</b>].
        </Paragraph>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
