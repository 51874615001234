import React, { useState, useEffect } from "react";
import { internship_duration } from "./Constant";
import { auth } from "../../../../Config/FirebaseConfig";
import { db } from "../../../../Config/FirebaseConfig";
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import "./UpdateCertificate.css";
function UpdateCertificate() {
  const [formData, setFormData] = useState({
    certificateId: "",
    name: "",
    college: "",
    internshipField: "",
    duration: "",
    from: "",
    to: "",
  });

  const [offerLetterFormData, setOfferLetterFormData] = useState({
    offerletter_id: "",
    name: "",
    college: "",
    position: "",
    mobile: "",
    offerdate: "",
  });

  const handleSubmitOfferLetter = async (e) => {
    e.preventDefault();

    try {
      const offerLetterDataRef = collection(db, "offerletterData"); // Assuming a collection named "offerLetterData"

      // Create a Firestore document with the form data
      const docRef = await addDoc(offerLetterDataRef, {
        offerletter_id: offerLetterFormData.offerletter_id.toUpperCase(),
        name: offerLetterFormData.name,
        college: offerLetterFormData.college,
        position: offerLetterFormData.position,
        mobile: offerLetterFormData.mobile,
        offerdate: offerLetterFormData.offerdate,
      });

      console.log("Document written with ID: ", docRef.id);

      // Clear the form after submission
      setOfferLetterFormData({
        offerletter_id: "",
        name: "",
        college: "",
        position: "",
        mobile: "",
      });

      alert("Offer Letter submitted successfully!"); // Provide user feedback
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to submit the Offer Letter."); // Provide user feedback
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOfferLetterFormData({ ...offerLetterFormData, [name]: value });
    // Calculate the "TO" date if "duration" changes and "FROM" is a valid date
    if (name === "duration" && formData.from) {
      const from = new Date(formData.from);
      if (!isNaN(from.getTime())) {
        // Check if "FROM" is a valid date
        const durationMonths = parseInt(value, 10);
        from.setMonth(from.getMonth() + durationMonths);

        const year = from.getFullYear();
        const month = String(from.getMonth() + 1).padStart(2, "0");
        const day = String(from.getDate()).padStart(2, "0");
        const to = `${year}-${month}-${day}`;

        setFormData({ ...formData, to, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const certificateDataRef = collection(db, "certificateData");

      // Create a Firestore document with the form data
      const docRef = await addDoc(certificateDataRef, {
        certificade_id: formData.certificateId.toUpperCase(),
        name: formData.name,
        college: formData.college,
        intern_field: formData.internshipField,
        duration: formData.duration,
        from: formData.from,
        to: formData.to,
      });

      console.log("Document written with ID: ", docRef.id);

      // Clear the form after submission
      setFormData({
        certificateId: "",
        name: "",
        college: "",
        internshipField: "",
        duration: "",
        from: "",
        to: "",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <div className="forms-container">
        <div className="form-wrapper">
          <h2>Certificate Form</h2>
          {/* Certificate form fields */}
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="certificateId">Certificate Id</label>
                <input
                  type="text"
                  className="form-control"
                  id="certificateId"
                  name="certificateId"
                  value={formData.certificateId}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="college">College</label>
                <input
                  type="text"
                  className="form-control"
                  id="college"
                  name="college"
                  value={formData.college}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="internshipField">Internship In field</label>
                <input
                  type="text"
                  className="form-control"
                  id="internshipField"
                  name="internshipField"
                  value={formData.internshipField}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="from">FROM</label>
              <input
                type="date"
                className="form-control"
                id="from"
                name="from"
                value={formData.from}
                onChange={handleChange}
              />
              <div className="form-group col-md-6">
                <label htmlFor="duration">Duration</label>
                <select
                  className="form-control"
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                >
                  <option value="">Select Duration</option>
                  {internship_duration.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="to">TO</label>
              <input
                type="date"
                className="form-control"
                id="to"
                name="to"
                value={formData.to}
                readOnly // Prevent manual input of "TO" date
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>

        <div className="form-wrapper">
          <h2>Offer Letter Form</h2>
          {/* OfferLetter form fields, similar to Certificate form fields */}
          <form onSubmit={handleSubmitOfferLetter}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="certificateId">OfferLetter Id</label>
                <input
                  type="text"
                  className="form-control"
                  id="offerletter_id"
                  name="offerletter_id"
                  value={offerLetterFormData.offerletter_id}
                  onChange={(e) => handleChange(e, "offerLetter")}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={offerLetterFormData.name}
                  onChange={(e) => handleChange(e, "name")}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="college">College</label>
                <input
                  type="text"
                  className="form-control"
                  id="college"
                  name="college"
                  value={offerLetterFormData.college}
                  onChange={(e) => handleChange(e, "college")}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="position">Position</label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  name="position"
                  value={offerLetterFormData.position}
                  onChange={(e) => handleChange(e, "position")}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="mobile">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  name="mobile"
                  value={offerLetterFormData.mobile}
                  onChange={(e) => handleChange(e, "mobile")}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="offerdate">Date Of Offer Letter</label>
                <input
                  type="text"
                  className="form-control"
                  id="offerdate"
                  name="offerdate"
                  value={offerLetterFormData.offerdate}
                  onChange={(e) => handleChange(e, "offerdate")}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary mt-2">
              Submit Offer Letter
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateCertificate;
