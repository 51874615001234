import React, { useState } from 'react';
import { Button } from 'antd';
import './verifypage.css'; // Ensure this path matches your file structure
import CertificateVerify from '../CertifateVerify/CertificateVerify'; // Corrected the path
import OfferLetter from '../Offer-Letter/OfferLetter';
import Navbar from '../../V1/Home-Comp/Navbar';

const VerifyPage = () => {
  const [activeComponent, setActiveComponent] = useState('certificate');

  // Function to determine button type based on active component
  const buttonType = (name) => activeComponent === name ? 'primary' : 'default';

  return (
    <>
      <Navbar />
      <div className="verify-page">
        <div className="button-group">
          <Button type={buttonType('certificate')} onClick={() => setActiveComponent('certificate')}>
            Verify Your Certificate
          </Button>
          <Button type={buttonType('offerLetter')} onClick={() => setActiveComponent('offerLetter')}>
            Verify Offer Letter
          </Button>
        </div>
        <div className="content">
          {activeComponent === 'certificate' ? <CertificateVerify /> : <OfferLetter />}
        </div>
        {/* Note section */}
        <div className="note">
          <p>
           <b>NOTE:-</b> If you find any inappropriate data please write us at <a href="mailto:mobikraftindia@gmail.com">mobikraftindia@gmail.com</a>.<br />
            <b>Mobikraft Learning </b>is the Educational Wing of <b>Mobikraft Tech PVT LTD</b>, CIN: U72900BR2021PTC055467.
          </p>
        </div>
      </div>
    </>
  );
};

export default VerifyPage;
