import React, { useEffect, useState } from "react";
import { db } from "../../../../Config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { Table, Input, Col, Row, Button } from "antd";
import styles from "../Dashboard-Tutor/InteractionAdmin.module.css";

const InteractionsAdminView = () => {
  const [interactions, setInteractions] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchInteractions = async () => {
      const interactionsCollectionRef = collection(db, "interactions");
      const data = await getDocs(interactionsCollectionRef);
      setInteractions(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchInteractions();
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredData = interactions.filter((interaction) => {
    return Object.keys(interaction).some((key) =>
      String(interaction[key]).toLowerCase().includes(searchText)
    );
  });

  const getWhatsAppUrl = (
    tutorMobile,
    tutorName,
    tutorCity,
    userName,
    userMobile
  ) => {
    const whatsappMessage = `Dear *${tutorName}*, we are very happy to inform you that a student has shown interest in your profile from ${tutorCity}. Student name: ${userName}, Student mobile: ${userMobile}. Please check your email for more details and contact as soon as possible. *MOBIKRAFT LEARNING*`;
    return `https://wa.me/91${tutorMobile}?text=${encodeURIComponent(
      whatsappMessage
    )}`;
  };

  const columns = [
    { title: "Tutor Email", dataIndex: "tutorEmail", key: "tutorEmail" },
    { title: "Tutor Mobile", dataIndex: "tutorMobile", key: "tutorMobile" },
    {
      title: "Send Msg",
      key: "sendMsg",
      render: (_, record) => (
        <a
          href={getWhatsAppUrl(
            record.tutorMobile,
            record.tutorname,
            record.tutorCity,
            record.userName,
            record.userMobile
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          Send Msg
        </a>
      ),
    },
    { title: "Tutor Name", dataIndex: "tutorname", key: "tutorname" },
    { title: "Tutor City", dataIndex: "tutorCity", key: "tutorCity" },
    { title: "Student Name", dataIndex: "userName", key: "userName" },
    { title: "Student Mobile", dataIndex: "userMobile", key: "userMobile" },
    { title: "Student Class", dataIndex: "userClass", key: "userClass" },
    {
      title: "Student Subjects",
      dataIndex: "userSubjects",
      key: "userSubjects",
      render: (subjects) => subjects.join(", "),
    },
    {
        title: "Time",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => {
          const date = createdAt
            ? new Date(createdAt.seconds * 1000)
            : new Date();
          return date.toLocaleString();
        },
        defaultSortOrder: 'descend', // Default sorting
        sorter: (a, b) => a.createdAt.seconds - b.createdAt.seconds, // Sort by createdAt timestamp
      },
  ];

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Input
            className={styles.searchInput}
            placeholder="Search by Mobile..."
            value={searchText}
            onChange={handleSearch}
          />
        </Col>
        <Col xs={24}>
          <div className={styles.tableContainer}>
            <h2 className={styles.overviewTitle}>Interactions Overview</h2>
            <Table
              dataSource={filteredData}
              columns={columns}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InteractionsAdminView;
