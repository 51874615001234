import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../Config/FirebaseConfig"; // Adjust this import according to your file structure
import Navbar from "../V1/Home-Comp/Navbar";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Use the useNavigate hook

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setEmail("");

    try {
        await sendPasswordResetEmail(auth, email);
        setMessage('Check your email for the password reset link');
        // Redirect to the sign-in page after a short delay
        setTimeout(() => navigate('/sign-in'), 3000);
      } catch (error) {
        setError('Failed to send password reset email. Make sure the email is correct.');
        console.error('Error sending password reset email:', error);
      }
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <div
          style={{
            width: "300px",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
          }}
        >
          <h2>Reset Password</h2>
          {message && <div className="alert alert-success">{message}</div>}
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2">
              Send Reset Link
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
