import React, { useState, useEffect } from "react";
import { Button, message } from "antd";

import {
  BhagalpurArea,
  JamuiArea,
  PatnaArea,
  DeogharArea,
  TeachingCity,
  RanchiArea,
  SubjectCombination,
  ClassYouCanTeach,
  Teaching_Exp,
  PostGrad_Degree,
  Grad_Degree,
  Class12Board,
  Class10Board,
  Teaching_Medium,
} from "./Constant";
import Select from "react-select";
import { db } from "../../../../Config/FirebaseConfig";
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import {
  ref, // Add this import
  uploadBytes, // Add this import
  getDownloadURL, // Add this import
} from "firebase/storage";

import { getStorage } from "firebase/storage";

const storage = getStorage();
const UpdateProfile = ({ isOpen, onClose, onSubmit }) => {
  const userDataFromLocalStorage = localStorage.getItem("user");
  const userDataObject = JSON.parse(userDataFromLocalStorage);
  const [imageUpload, setImageUpload] = useState(null); // For new file selection
  const [idProofUpload, setIdProofUpload] = useState(null); // For new file selection
  const [existingProfilePicUrl, setExistingProfilePicUrl] = useState(""); // URL from DB
  const [existingIdProofUrl, setExistingIdProofUrl] = useState(""); // URL from DB
  const [isOtherCitySelected, setIsOtherCitySelected] = useState(false);
  const [otherCity, setOtherCity] = useState(""); // State to store the other city name
  const [
    filteredSubjectCombinationOptions,
    setFilteredSubjectCombinationOptions,
  ] = useState([]);

  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iduploadError, setIduploadError] = useState("");
  const [picuploadError, setPicuploadError] = useState("");

  const [formData, setFormData] = useState({
    tenthBoard: "",
    twenthBoard: "",
    diploma: "",
    graduation: "",
    postGraduation: "",
    AnyTeachingExperience: "",
    idProof: null,
    profilePic: null,
    MediumOfTeaching: "",
    ClassYouCanTeach: [],
    SubjectCombination: [],
    teachingCity: "",
    cityLocation: [],
  });

  const classToSubjectsMap = {
    "UP TO 1": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
    "1-3": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
    "3-5": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
    "6-8": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
    "9-10": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
    "11-12": [
      "ENGLISH",
      "HINDI",
      "SANSKRIT",
      "MATH",
      "PHYSICS",
      "CHEMISTRY",
      "BIOLOGY",
      "SCIENCE",
      "SST",
      "HISTORY",
      "CIVICS",
      "GEOGRAPHY",
      "POLITICAL SCI",
      "BASIC-COMPUTER",
      "COMPUTER SCI",
      "CODING",
    ],
  };

  const imagesListRef = ref(storage, "images/");

  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "#fefefe",
    margin: "15% auto",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  };

  const closeButtonStyle = {
    color: "#aaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const cityToAreaMap = {
    BHAGALPUR: BhagalpurArea,
    DEOGHAR: DeogharArea,
    JAMUI: JamuiArea,
    PATNA: PatnaArea,
    RANCHI: RanchiArea,
    // ... other city mappings
  };

  // fetching data when pages loads

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const userUid = userDataObject.uid;
    const userDocRef = doc(db, "users", userUid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();

        // Set the existing profile picture and ID proof URLs if they exist
        setExistingProfilePicUrl(userData.profilePic || "");
        setExistingIdProofUrl(userData.idProof || "");

        // Update the form state with fetched data, excluding profilePic and idProof
        const { profilePic, idProof, ...otherData } = userData;
        setFormData({
          ...formData,
          ...otherData,
        });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const handleClassChange2 = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value);
    setFormData({
      ...formData,
      ClassYouCanTeach: selectedValues,
    });

    // Filter subject combinations based on the selected classes
    const availableSubjects = selectedValues
      .flatMap((classValue) => classToSubjectsMap[classValue] || [])
      .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates

    // Update available subjects for the SubjectCombination select input
    const subjectOptions = availableSubjects.map((subject) => ({
      label: subject,
      value: subject,
    }));
    setFilteredSubjectCombinationOptions(subjectOptions);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAreaChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value);
    setFormData({
      ...formData,
      cityLocation: selectedValues,
    });
  };

  const handleSubjectCombinationChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value);
    setFormData({
      ...formData,
      SubjectCombination: selectedValues,
    });
  };
  const handleClassChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value);
    setFormData({
      ...formData,
      ClassYouCanTeach: selectedValues,
    });
  };
  const handleTenthBoardChange = (e) => {
    const selectedTenthBoard = e.target.value;
    setFormData({
      ...formData,
      tenthBoard: selectedTenthBoard,
    });
  };
  const handleTwelfthBoardChange = (e) => {
    const selectedTwelfthBoard = e.target.value;
    setFormData({
      ...formData,
      twenthBoard: selectedTwelfthBoard,
    });
  };

  const handleDiplomaChange = (e) => {
    const selecteddiploma = e.target.value;
    setFormData({
      ...formData,
      diploma: selecteddiploma,
    });
  };

  const handleGradBoardChange = (e) => {
    const selectedGradBoard = e.target.value;
    setFormData({
      ...formData,
      graduation: selectedGradBoard,
    });
  };

  const handlePgBoardChange = (e) => {
    const selectedPgBoard = e.target.value;
    setFormData({
      ...formData,
      postGraduation: selectedPgBoard,
    });
  };

  const handleTeachChange = (e) => {
    const selectedExp = e.target.value;
    setFormData({
      ...formData,
      AnyTeachingExperience: selectedExp,
    });
  };
  const handleMedChange = (e) => {
    const selecteMed = e.target.value;
    setFormData({
      ...formData,
      MediumOfTeaching: selecteMed,
    });
  };
  const handleProfilePicUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check if file size is greater than 1MB
    if (file.size > 1048576) {
      // 1MB in bytes
      setPicuploadError("Profile picture size should not exceed 1MB.");
      e.target.value = ""; // Clear the selected file
      return;
    } else {
      setPicuploadError(""); // Clear any existing error messages
    }

    setImageUpload(file); // Store the file for later upload in handleSubmit

    // Generate a unique file name
    let _id = Math.floor(Math.random() * 1000000000);
    const imageRef = ref(storage, `images/${file.name}_${_id}`);

    try {
      const snapshot = await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePic: downloadURL, // Save the download URL
      }));
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const handleIdProofUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check if file size is greater than 1MB
    if (file.size > 1048576) {
      // 1MB in bytes
      // Show an alert or a visible notification below
      // For example, setting a state variable to show an error message
      setIduploadError("Id Proof size should not exceed 1MB.");

      // De-select the file
      e.target.value = ""; // Resetting the file input
      return;
    } else {
      setIduploadError(""); // Clearing any previous error message
    }

    setIdProofUpload(file); // Store the file for later upload in handleSubmit

    // Generate a unique file name
    let _id = Math.floor(Math.random() * 1000000000);
    const idProofRef = ref(storage, `idproofs/${file.name}_${_id}`);

    try {
      const snapshot = await uploadBytes(idProofRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      console.log("ID Proof uploaded:", downloadURL); // Check if URL is retrieved

      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          idProof: downloadURL, // Update the formData with new URL
        };
      });
    } catch (error) {
      console.error("Error uploading ID proof: ", error);
    }
  };

  const handleTeachingCityChange2 = (e) => {
    const selectedTeachingCity = e.target.value;
    setFormData({
      ...formData,
      teachingCity: selectedTeachingCity,
      cityLocation: [], // Reset cityLocation when teachingCity changes
    });
  };

  const handleTeachingCityChange = (e) => {
    const selectedTeachingCity = e.target.value;
    // Check if "OTHER" is selected
    if (selectedTeachingCity === "OTHER") {
      setIsOtherCitySelected(true);
    } else {
      setIsOtherCitySelected(false);
    }
    setFormData({
      ...formData,
      teachingCity: selectedTeachingCity,
      cityLocation: [], // Reset cityLocation when teachingCity changes
    });
  };

  const handleOtherCityChange = (e) => {
    const city = e.target.value;
    setOtherCity(city);
    setFormData({
      ...formData,
      teachingCity: city,
    });
  };
  const renderAllAreasDropdown = () => {
    const areas = cityToAreaMap[formData.teachingCity] || [];
    const options = areas.map((area) => ({ label: area, value: area }));
    const selectedValues = formData.cityLocation?.map((area) => ({
      label: area,
      value: area,
    }));

    return (
      <div className="form-group">
        <label>
          City Location {""}
          <span style={{ color: "red", fontSize: "12px" }}>
            (Multiple Select)
          </span>
          :
        </label>
        <Select
          isMulti
          options={options}
          value={selectedValues}
          onChange={handleAreaChange}
        />
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let profilePicUrl = existingProfilePicUrl;
    if (imageUpload) {
      const imageRef = ref(
        storage,
        `images/${imageUpload.name}_${new Date().getTime()}`
      );
      try {
        const snapshot = await uploadBytes(imageRef, imageUpload);
        profilePicUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading profile picture: ", error);
        message.error("Failed to upload profile picture.");
        setLoading(false); // Stop loading on error
        return; // Exit the function if there's an error
      }
    }

    let idProofUrl = existingIdProofUrl;
    if (idProofUpload) {
      const idProofRef = ref(
        storage,
        `idproofs/${idProofUpload.name}_${new Date().getTime()}`
      );
      try {
        const snapshot = await uploadBytes(idProofRef, idProofUpload);
        idProofUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading ID proof: ", error);
        message.error("Failed to upload ID proof.");
        setLoading(false); // Stop loading on error
        return; // Exit the function if there's an error
      }
    }

    try {
      const usersCollectionRef = collection(db, "users");
      const userUid = userDataObject.uid;
      const userDocRef = doc(usersCollectionRef, userUid);

      await setDoc(
        userDocRef,
        {
          ...formData,
          profilePic: profilePicUrl,
          idProof: idProofUrl,
          isVerified: false,
        },
        { merge: true }
      );

      message.success("Profile updated successfully!");
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error updating document: ", error);
      message.error("Failed to update profile.");
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Handle file upload for profile picture if a new file is selected
    let profilePicUrl = existingProfilePicUrl;
    if (imageUpload) {
      const imageRef = ref(
        storage,
        `images/${imageUpload.name}_${new Date().getTime()}`
      );
      try {
        const snapshot = await uploadBytes(imageRef, imageUpload);
        profilePicUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading profile picture: ", error);
      }
    }

    // Handle file upload for ID proof if a new file is selected
    let idProofUrl = existingIdProofUrl;
    if (idProofUpload) {
      const idProofRef = ref(
        storage,
        `idproofs/${idProofUpload.name}_${new Date().getTime()}`
      );
      try {
        const snapshot = await uploadBytes(idProofRef, idProofUpload);
        idProofUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading ID proof: ", error);
      }
    }

    // Update the user document with the new or existing URLs
    try {
      const usersCollectionRef = collection(db, "users");
      const userUid = userDataObject.uid;
      const userDocRef = doc(usersCollectionRef, userUid);

      await setDoc(
        userDocRef,
        {
          ...formData,
          profilePic: profilePicUrl,
          idProof: idProofUrl,
          isVerified: false,
        },
        { merge: true }
      );

      setShowSuccessNotification(true);

      // Close the modal and reset the form after a delay
      setTimeout(() => {
        setShowSuccessNotification(false);
        onClose(); // Call the onClose function to close the modal
      }, 3000);
    } catch (error) {
      console.error("Error updating document: ", error);
      // Optionally, handle error notification here
    }
  };

  return (
    <div style={modalStyle} id="myModal">
      <div style={modalContentStyle}>
        <span style={closeButtonStyle} onClick={onClose}>
          &times;
        </span>
        <h2>Update Profile</h2>
        <div className="container mt-5">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>10th Board:</label>
                  <select
                    className="form-control"
                    name="tenthBoard"
                    value={formData.tenthBoard}
                    onChange={handleTenthBoardChange}
                  >
                    <option value="">Select Board</option>
                    {Class10Board?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>12th Board:</label>
                  <select
                    className="form-control"
                    name="twenthBoard"
                    value={formData.twenthBoard}
                    onChange={handleTwelfthBoardChange}
                  >
                    <option value="">Select Board</option>
                    {Class12Board?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Diploma:</label>
                  <select
                    className="form-control"
                    name="diploma"
                    value={formData.diploma}
                    onChange={handleDiplomaChange}
                  >
                    <option value="">Select Board</option>
                    {Class12Board?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Graduation:</label>
                  <select
                    className="form-control"
                    name="graduation"
                    value={formData.graduation}
                    onChange={handleGradBoardChange}
                  >
                    <option value="">Select Board</option>
                    {Grad_Degree?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Post Graduation:</label>
                  <select
                    className="form-control"
                    name="postGraduation"
                    value={formData.postGraduation}
                    onChange={handlePgBoardChange}
                  >
                    <option value="">Select Board</option>
                    {PostGrad_Degree?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Any Teaching Experience:</label>
                  <select
                    className="form-control"
                    name="AnyTeachingExperience"
                    value={formData.AnyTeachingExperience}
                    onChange={handleTeachChange}
                  >
                    <option value="">Select Experience </option>
                    {Teaching_Exp?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Medium Of Teaching:</label>
                  <select
                    className="form-control"
                    name="MediumOfTeaching"
                    value={formData.MediumOfTeaching}
                    onChange={handleMedChange}
                  >
                    <option value="">Select Medium</option>
                    {Teaching_Medium?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>
                    Class You Can Teach{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      (Multiple Select)
                    </span>
                    :
                  </label>
                  <Select
                    isMulti
                    options={ClassYouCanTeach?.map((classes) => ({
                      label: classes,
                      value: classes,
                    }))}
                    value={formData.ClassYouCanTeach?.map((classes) => ({
                      label: classes,
                      value: classes,
                    }))}
                    onChange={handleClassChange}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>
                    Subject Combination{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      (Multiple Select)
                    </span>
                    :
                  </label>
                  <Select
                    isMulti
                    options={SubjectCombination?.map((subject) => ({
                      label: subject,
                      value: subject,
                    }))}
                    value={formData.SubjectCombination?.map((subject) => ({
                      label: subject,
                      value: subject,
                    }))}
                    onChange={handleSubjectCombinationChange}
                  />
                </div>
              </div>

              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Teaching City:</label>
                  <select
                    className="form-control"
                    name="teachingCity"
                    value={formData.teachingCity}
                    onChange={handleTeachingCityChange}
                  >
                    <option value="">Select a city</option>
                    {TeachingCity?.map((cityName, index) => (
                      <option key={index} value={cityName}>
                        {cityName}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}

              {isOtherCitySelected ? (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Other City:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="teachingCity"
                      value={otherCity}
                      onChange={handleOtherCityChange}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Teaching City:</label>
                    <select
                      className="form-control"
                      name="teachingCity"
                      value={formData.teachingCity}
                      onChange={handleTeachingCityChange}
                    >
                      <option value="">Select a city</option>
                      {TeachingCity?.map((cityName, index) => (
                        <option key={index} value={cityName}>
                          {cityName}
                        </option>
                      ))}
                      <option value="OTHER">Other</option>{" "}
                      {/* Add an "OTHER" option */}
                    </select>
                  </div>
                </div>
              )}

              <div className="col-lg-4 col-md-6 col-sm-12">
                {renderAllAreasDropdown()}
              </div>
              <br />
              <hr />

              <div className="col-lg-4 col-md-6 col-sm-12">
                {existingProfilePicUrl && (
                  <img
                    src={existingProfilePicUrl}
                    alt="Profile"
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
                <label>Your Picture:</label>

                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  name="profilePic"
                  onChange={handleProfilePicUpload}
                />
                {/* Display error message for ID proof if any */}
                {picuploadError && (
                  <p className="text-danger">{picuploadError}</p>
                )}
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                {existingIdProofUrl && (
                  <a href={existingIdProofUrl}>View ID Proof</a>
                )}{" "}
                <label>Id Proof:(Aadhar, Voter Id etc)</label>
                <input
                  type="file"
                  accept="image/*, application/pdf"
                  className="form-control"
                  name="idProof"
                  onChange={handleIdProofUpload}
                />
                {/* Display error message for ID proof if any */}
                {iduploadError && (
                  <p className="text-danger">{iduploadError}</p>
                )}
              </div>
              <Button
                type="primary"
                loading={loading}
                onClick={handleSubmit}
                className="mt-2"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
        {showSuccessNotification && (
          <div className="success-notification">
            Profile updated successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateProfile;
