import React, { useState } from "react";
import { Modal, Button, Select, Input, Form, notification } from "antd";
import emailjs from "@emailjs/browser";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../../Config/FirebaseConfig";
import {
  StudentClassChoices,
  SubjectCombination,
} from "../../V2/Tutor-Profile/Dashboard-Tutor/Constant";
const CustomModal = ({
  visible,
  handleOk,
  contactNumber,
  handleCancel,
  tutoremail,
  tutorname,
  tutorId,
  tutorCity,
}) => {
  const [formData, setFormData] = useState({
    userName: "",
    userMobile: "",
    userEmail: "",
    userClass: "",
    userSubjects: [],
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Check if all required fields are filled
  const allFieldsFilled = () => {
    return (
      formData.userName &&
      formData.userMobile &&
      formData.userClass &&
      formData.userSubjects.length > 0
    );
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      from_name: formData.userName,
      message: `Name: ${formData.userName}, Mobile No: ${formData.userMobile}, Class: ${formData.userClass}, Subjects Choice: ${formData.userSubjects}`,
      to_email: tutoremail,
      tutor_name: tutorname,
      tutor_contactNo: contactNumber,
    };

    try {
      const response = await emailjs.send(
        "service_46urz8q",
        "template_78i7hve",
        templateParams,
        "SwFwSYJqiBhK3nLEx"
      );
      console.log("EmailJS Response:", response);

      notification.success({
        message: "Success",
        description: "Tutor Will Connect You Shortly..",
        type: "success",
      });

      // Explicitly calling storeInteractionData
      await storeInteractionData({
        tutorEmail: tutoremail,
        tutorMobile: contactNumber,
        tutorCity: tutorCity,
        tutorname: tutorname,
        tutorId: tutorId,
        userName: formData.userName,
        userMobile: formData.userMobile,
        userClass: formData.userClass,
        userSubjects: formData.userSubjects,
      });

      handleCancel(); // Close the modal after successful email sending
    } catch (error) {
      console.error("Failed to send email or store data:", error);
      notification.error({
        message: "Error",
        description: "Failed to send email or store data.",
        type: "error",
      });
    }

    setLoading(false); // Stop loading after the email sending process is completed or if an error occurs
  };

  const storeInteractionData = async (data) => {
    try {
      const completeData = {
        ...data,
        createdAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(db, "interactions"), completeData);
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error storing interaction data: ", error);
    }
  };

  return (
    <div>
      <Modal
        title="Enter Details Tutor Will Connect With You"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item required label="Student Name">
            <Input
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              placeholder="Enter your name"
            />
          </Form.Item>

          <Form.Item
            label="Mobile Number"
            required
            rules={[
              {
                required: true,
                message: "Please input your Mobile Number!",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Contact No must be 10 digits!",
              },
            ]}
          >
            <Input
              type="tel"
              name="userMobile"
              value={formData.userMobile}
              onChange={handleChange}
              maxLength={10}
              placeholder="Enter your mobile number"
            />
          </Form.Item>

          {/* <Form.Item required label="Email Id">
            <Input
              type="email" // Set input type to "email" for email input
              name="userEmail"
              value={formData.userEmail}
              onChange={handleChange}
              placeholder="Enter your Email"
            />
          </Form.Item> */}
          <Form.Item required label="Class">
            <Select
              name="userClass"
              value={formData.userClass}
              onChange={(value) => handleSelectChange("userClass", value)}
              placeholder="Select your class"
            >
              {StudentClassChoices?.map((className) => (
                <Select.Option key={className} value={className}>
                  {className}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item required label="Subjects (Multiple Select)">
            <Select
              mode="multiple"
              name="userSubjects"
              value={formData.userSubjects}
              onChange={(value) => handleSelectChange("userSubjects", value)}
              placeholder="Select your subjects"
            >
              {SubjectCombination.map((subject) => (
                <Select.Option key={subject} value={subject}>
                  {subject}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={sendEmail}
              disabled={!allFieldsFilled()}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CustomModal;
