import React from "react";
import { Typography } from "antd";
import styles from "./privacyPolicy.module.css"; // Ensure the CSS module is correctly linked
import Navbar from "../../Components/V1/Home-Comp/Navbar";
import Footer from "../../Components/V1/Footer-Comp/Footer";

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className={styles.privacyPolicyContainer}>
        <Title level={2}>Privacy Policy for Mobikraft Learning</Title>
        <Paragraph>Last updated: [20-Mar-2024]</Paragraph>

        <Paragraph>
          Welcome to Mobikraft Learning, the education wing of Mobikraft Tech
          PVT LTD, dedicated to connecting students with verified home tutors to
          enhance their learning journey. Your privacy is paramount to us, and
          this Privacy Policy outlines the practices regarding the collection,
          use, protection, and sharing of your information on our website,
          www.mobikraftlearning.in.
        </Paragraph>

        <Title level={3}>Information We Collect</Title>
        <Paragraph>
          When you use Mobikraft Learning, we may collect the following
          information:
          <ul>
            <li>
              <strong>Personal Information:</strong> This includes your name,
              email address, contact number, and location. For tutors, we also
              collect additional details such as profile photos and ID proof to
              verify their identity.
            </li>
            <li>
              <strong>Usage Information:</strong> We gather data about how you
              interact with our services, which assists us in improving our
              platform and tailoring it to your needs.
            </li>
          </ul>
        </Paragraph>

        <Title level={3}>How We Use Your Information</Title>
        <Paragraph>
          The information we collect is used for the following purposes:
          <ul>
            <li>
              To Provide Our Services: We use your information to register tutor
              profiles, manage accounts, and facilitate connections between
              students and tutors.
            </li>
            <li>
              To Improve Our Platform: Your usage information helps us
              understand user behavior and preferences, enabling us to enhance
              the features and functionality of Mobikraft Learning.
            </li>
            <li>
              To Communicate: We may use your information to send important
              updates, respond to inquiries, or provide customer support.
            </li>
          </ul>
        </Paragraph>

        <Title level={3}>Sharing of Information</Title>
        <Paragraph>
          We respect your privacy and are committed to protecting it through our
          compliance with this policy. We do not share the contact details of
          tutors with students. Tutor information is only used to facilitate the
          connection with students through our platform.
        </Paragraph>

        <Title level={3}>Data Security</Title>
        <Paragraph>
          We implement suitable security measures to protect against
          unauthorized access, alteration, disclosure, or destruction of your
          personal information. However, no method of transmission over the
          Internet or electronic storage is completely secure, and we cannot
          guarantee its absolute security.
        </Paragraph>

        <Title level={3}>Changes to This Privacy Policy</Title>
        <Paragraph>
          Mobikraft Learning reserves the right to update this Privacy Policy at
          any time. We will notify you of any changes by posting the new Privacy
          Policy on our website. You are advised to review this Privacy Policy
          periodically for any changes.
        </Paragraph>

        <Title level={3}>Contact Us</Title>
        <Paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at [<b>mobikraftindia@gmail.com</b>].
        </Paragraph>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
