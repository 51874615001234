import React, { useState } from "react";
import Seat from "./Seat";
import "./SeatMap.css";

// Define your seat layout; for simplicity, we use a 5x5 grid
const createInitialSeats = () => {
  const seats = [];
  for (let i = 0; i < 204; i++) {
    seats.push({
      id: `seat-${i}`,
      isSelected: false,
    });
  }
  return seats;
};

const SeatMap = () => {
  const [seats, setSeats] = useState(createInitialSeats());

  const toggleSeatSelection = (selectedId) => {
    const newSeats = seats.map((seat) => {
      // Set isSelected to true only for the clicked seat, and false for others
      if (seat.id === selectedId) {
        return { ...seat, isSelected: !seat.isSelected };
      } else {
        // Deselect any other seats
        return { ...seat, isSelected: false };
      }
    });
    // If the selected seat is already selected, allow it to be deselected
    const alreadySelected = seats.some(
      (seat) => seat.id === selectedId && seat.isSelected
    );
    if (alreadySelected) {
      setSeats(createInitialSeats()); // Reset all seats to unselected
    } else {
      setSeats(newSeats); // Apply the new selection state
    }
  };

  return (
    <div className="seat-map">
      {seats.map((seat) => (
        <Seat
          key={seat.id}
          id={seat.id}
          isSelected={seat.isSelected}
          onToggle={toggleSeatSelection}
        />
      ))}
    </div>
  );
};

export default SeatMap;
