export const TeachingCity = [
  "BHAGALPUR",
  "DEOGHAR",
  "JAMUI",
  "PATNA",
  "RANCHI",
];

export const BhagalpurArea = [
  "Adampur",
  "Barahpura",
  "Bhagalpur",
  "Bhaglapur Central Jail",
  "AIRPORT AREA",
  "Ishakchak",
  "Kharmanchak",
  "Mayaganj",
  "Tilkamanjhi",
  "SM COLLEGE AREA",
  "ZEROMILE AREA",
  "RANIGANJ",
  "ENGINEERING College Area",
  "SABOUR",
  "SARAI",
  "TNB University Area",
  "TNB Clg Area",
  "TMU University Area",
  "Champa Nagar",
  "NATH NAGAR",
  "KHALIFABHAG",
  "BARARI",
  "GHANTA GHAR AREA",
  "EMAMPUR",
  "HABIBPUR",
  "PATAL BABU RD",
  "BIKHANPUR",
  "KACHARI AREA",
  "BUDHANATH AREA",
  "LALUCHACK BHATTA RD",
  "LODIPUR AREA",
  "OTHER",
];

export const RanchiArea = [
  "LALPUR",
  "MAIN ROAD",
  "RLY STATION",
  "LOWER CHUTIA",
  "UPPER CHUTIA",
  "LOWADIH",
  "KISHOREGANJ",
  "ASHOK NAGAR",
  "KOKAR",
  "KATATOLI AREA",
  "ARGORA",
  "RATU ROAD",
  "CIRCULAR ROAD AREA",
  "Hindpiri",
  "Hehal",
  "Hinoo",
  "TIPUDANA",
  "Lower Bazar",
  "UPPER BAZAR",
  "Pundag",
  "Pandra",
  "Kamre",
  "Morabadi",
  "Ashok Nagar",
  "HighCourt Area",
  "Hatia",
  "Harmu Area",
  "Harmu Housing Colony",
  "Birsa Chowk",
  "Bahu Bazar",
  "Bariatu",
  "Booty More",
  "Daladali",
  "Kathal More",
  "Bajra",
  "FIRYALAL CHOWK",
  "GOSSNER Clg Area",
  "OTHER",
];

export const DeogharArea = [
  "Bilasi",
  "Baidyanath Pur",
  "Pani Tanki (Pvt Bus Stand)",
  "Mandir More",
  "Jhoushagari",
  "Khunda",
  "VIP Chock",
  "Satsang",
  "Tower Chowk",
  "Baidyanath Dham Rly Stn",
  "Deoghar College",
  "Castairs Town",
  "William Town",
  "Bompas Town",
  "Bajla Chowk",
  "Nandan Pahar",
  "Jasidih Area",
  "Dabur Gram",
  "Barmaisa",
  "Krishna Puri",
  "OTHER",
];

export const JamuiArea = [
  "Maharajganj",
  "Bodhwan Talab Area",
  "Mahisouri Area",
  "Purani Bazar",
  "Shivandih",
  "Panch Mandir",
  "By Pass Road",
  "Bihari",
  "Kalyanpur",
  "KKM College Area",
  "Shitla Colony",
  "Shrichand Nawada",
  "VIP Colony",
  "Shastri Colony",
  "Satgama",
  "Khairma",
  "Malaypur",
  "Oxfoard School Area",
  "Govt Engineering College Area",
  "OTHER",
];

export const PatnaArea = [
  "KankarBhag",
  "Boring ROAD",
  "RLY STATION Area",
  "Gandhi Maidan",
  "OTHER",
];

export const SubjectCombination = [
  "ENGLISH",
  "HINDI",
  "SANSKRIT",
  "MATH",
  "PHYSICS",
  "CHEMISTRY",
  "BIOLOGY",
  "SCIENCE",
  "SST",
  "HISTORY",
  "CIVICS",
  "GEOGRAPHY",
  "POLITICAL SCI",
  "BASIC-COMPUTER",
  "COMPUTER SCI",
  "CODING",
];
export const ClassYouCanTeach = [
  "UP TO 1",
  "1-3",
  "3-5",
  "6-8",
  "9-10",
  "11-12",
];

export const StudentClassChoices = [
  "LKG",
  "UKG",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const Class10Board = [
  "CBSE",
  "ICSE",
  "BSEB",
  "JAC",
  "WEST-BENGAL",
  "OTHER",
  "N/A",
];

export const Class12Board = [
  "CBSE",
  "ICSE",
  "BSEB",
  "JAC",
  "WEST-BENGAL",
  "OTHER",
  "N/A",
];

export const Grad_Degree = [
  "BA",
  "BSC",
  "BCOM",
  "B-TECH",
  "MBBS",
  "BBA",
  "OTHER",
  "N/A",
];

export const PostGrad_Degree = [
  "MA",
  "MSC",
  "MCOM",
  "M-TECH",
  "MBA",
  "OTHER",
  "N/A",
];

export const Teaching_Exp = [
  "UP To 6 Month",
  "1 Year",
  "2 Year",
  "3 Year",
  "5 Year",
];

export const Teaching_Medium = [
  "English",
  "Hindi",
  "Hinglish",
  "English & Hindi",
  "ALL",
];

export const internship_duration = [
  { value: "1", label: "1 Month" },
  { value: "2", label: "2 Months" },
  { value: "3", label: "3 Months" },
  { value: "4", label: "4 Months" },
  { value: "5", label: "5 Months" },
  { value: "6", label: "6 Months" },
  { value: "7", label: "7 Months" },
  { value: "8", label: "8 Months" },
  { value: "9", label: "9 Months" },
  { value: "10", label: "10 Months" },
  { value: "11", label: "11 Months" },
  { value: "12", label: "12 Months" },
];

export const Nortification_msg_Find_Tutor = [
  "10 People search for Tutor In Bhagalpur in Last hour",
  "19 People search for Tutor In Deoghar in Last hour",
  "31 People search for Tutor In Ranchi in Last hour",
];

export const tutorEmail_List = [
  "Uttamkumar74500@gmail.com",
  "gauravsh4400@gmail.com",
  "sandeepchas1998@gmail.com",
  "itsnehagupta2003@gmail.com",
  "aasimhayatkhan@gmail.com",
  "beautykri8629@gmail.com",
  "manishkumar46454645@gmail.com",
  "amankumart114@gmail.com",
  "avinashpersonal0001@gmail.com",
  "anjalikumaribgp319@gmail.com",
  "anuragmech09@gmail.com",
  "bittu2002mahato@gmail.com",
  "LOVELYGUPTA2720@GMAIL.COM",
  "sujitgupta449@gmail.com",
  "deveshgautam43@gmail.com",
  "silentloverkrish@gmail.com",
  "rahulanand11032014@gmail.com",
  "jhasharda72@gmail.com",
  "akankshakumari060@gmail.com",
  "akbarmahmoodwaris@gmail.com",
  "shekharprasd@gmail.com",
  "nikitakumarirno@gmail.com",
  "kuldipray07@gmail.com",
  "nileshsingh330@gmail.com",
  "ayushkumar6200850551@gmail.com",
  "sanchisingh578@gmail.com",
  "devaryansinha@gmail.com",
  "manojmayurhand@gmail.com",
  "shivamkryadav1221@gmail.com",
  "Priyakumari42365482@gmail.com",
  "rahulkr14032002@gmail.com",
  "krahul12698@gmail.com",
  "nirajthakura@gmail.com",
  "shilpigupta09874@gmail.com",
  "sadhanasingh3727@gmail.com",
  "sashachoudhary13@gmail.com",
  "mritunjaymonu62037@gmail.com",
  "panditraghu884@gmail.com",
  "bhaskar8539@gmail.com",
  "sumonadok@gmail.com",
  "princeupadhyay647@gmail.com",
  "sumitsingh8804603888@gmail.com",
  "surajk21473@gmail.com",
  "ayu624548@gmail.com",
  "piyushkumar61118@gmail.com",
  "janvi989978@gmail.com",
  "sunainarajput1603@gmail.com",
  "ssujata029@gmail.com",
  "prabhakarkumar0099@gmail.com",
  "Textme8434@gmail.com",
  "gk5314044@gmail.com",
  "mandalrahul8241@gmail.com",
  "avirsr38@gmail.com",
  "shruti9308362651@gmail.com",
  "satyajitprakashsp99@gmail.com",
  "sanjaykumarmandal7050@gmail.com",
  "rahulsinha7698@gmail.com",
  "nileshsinghwork001@gmail.com",
  "omjeehopes12@gmail.com",
  "abhinandanpritam4321@gmail.com",
  "indrajeet00kumar@gmail.com",
  "royalakansha1999@gmail.com",
  "aftabad46@gmail.com",
  "sachitkumarverma753@gmail.com",
  "rahulmahato3951@gmail.com",
  "ab6176850@gmail.com",
  "mamtakumarimehta99@gmail.com",
  "devinitu2089@gmail.com",
  "ar4128039@gmail.com",
  "barnwalshubhamkumar@gmail.com",
  "kumarritubasant140626@gmail.com",
  "swetakumarisah001@gmail.com",
  "princekumarchain1@gmail.com",
  "Kumarnavin2514@gmail.com",
  "kumarsatyam7761867812@gmail.com",
  "mithunbhartipccbanka@gmail.com",
  "krishandevkumar50@gmail.com",
  "shrikant9102822195@gmail.com",
  "jaydevsinghchoudhary@gmail.com",
  "khusu841996@gmail.com",
  "nimishabgp92@gmail.com",
  "skpankaj.1983@gmail.com",
  "aryanraj96345@gmail.com",
  "musk08kan@gmail.com",
  "kumarip18391@gmail.com",
  "ahmadishu725@gmail.com",
  "udaysarath707@gmail.com",
  "kamleshkumar99058@gmail.com",
  "dhirendraya234@gmail.com",
  "sumantdas6207@gmail.com",
  "Sharmakumkum1312@gmail.com",
  "vikrampandit345@gmail.com",
  "chandan44beauty@gmail.com",
  "vikramvishal870@gmail.com",
  "svvmroy@gmail.com",
  "rajnariyanyadev@gmail.com",
  "reemag598@gmail.com",
  "prince52141raj@gmail.com",
  "satyam0prasad0@gmail.com",
  "Sj397631@gmail.com",
  "mukeshprasad2090@gmail.com",
  "singhpallavi06348@gmail.com",
  "khalkhopunam886@gmail.com",
  "prince.bho1213@gmail.com",
  "saketk603@gmail.com",
  "amankrgupta1996@gmail.com",
  "dipakashyap112233@gmail.com",
  "Kumaramargit770@gmail.com",
  "srishtygoyal57@gmail.com",
  "ayushkumardwivedi72@gmail.com",
  "hk234570@gmail.com",
  "shashwatsah59@gmail.com",
  "kundanbahardar@gmail.com",
  "12prajapati1@gmail.com",
  "samarsbv@gmail.com",
  "niteshrajsingh40@gmail.com",
];
