import React, { useState, useEffect } from "react";
import Navbar from "../../../V1/Home-Comp/Navbar";
import UpdateProfile from "./UpdateProfile";
import { Button, Image, Modal, Card, Spin } from "antd";
import { auth } from "../../../../Config/FirebaseConfig";
import {
  HomeOutlined,
  LoadingOutlined,
  SettingFilled,
  EnvironmentOutlined,
  ShoppingCartOutlined,
  BellOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { Alert, Space } from "antd";
import { Typography } from "antd";

import { db } from "../../../../Config/FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
const DashboardTutor = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const userDataFromLocalStorage = localStorage.getItem("user");
  const userDataObject = JSON.parse(userDataFromLocalStorage);

  const { Title, Text } = Typography;
  // Now `userDataObject` contains the data as a JSON object

  useEffect(() => {
    // Initialize Firestore
    const db = getFirestore();

    // Reference the document using the user's UID
    const userDocRef = doc(db, "users", userDataObject?.uid); // Replace "users" with your collection name

    // Retrieve the data from Firestore
    const getUserData = async () => {
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          // User document exists, and you can access its data
          const userData = userDoc.data();
          setUserData(userData); // Store the user data in the state
        } else {
          // User document does not exist
          console.log("User document not found.");
        }
      } catch (error) {
        // Handle errors here
        console.error("Error getting user data:", error);
      }
    };

    // Call the function to retrieve user data
    getUserData();
  }, [userDataObject.uid]);
  const handleLogout = () => {
    // Clear the token and perform any other necessary logout actions
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    // Redirect to the login page
    window.location.href = "/sign-in";
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleSubmit = (formData) => {
    // Handle form submission here
    closePopup();
  };

  return (
    <>
      <Navbar />
      <section style={{ backgroundColor: "#eee" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <nav
                aria-label="breadcrumb"
                className="bg-light rounded-3 p-3 mb-4 d-flex justify-content-between align-items-center"
              >
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">User</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Profile
                  </li>
                </ol>
                <button className="btn btn-danger" onClick={handleLogout}>
                  Logout
                </button>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src={userData?.profilePic}
                    alt={userData.fullname}
                    className="rounded-circle img-fluid"
                    style={{ width: 150, height: 150 }}
                  />
                  <h5 className="my-3">{userData?.fullname}</h5>
                  <p className="text-muted mb-1">
                    {userData?.accountType} in <b>{userData?.teachingCity}</b>
                  </p>
                  {/* <p className="text-muted mb-4">Bay Area, San Francisco, CA</p> */}
                  <div className="d-flex justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                      {userData?.accountType === "Tutor" && (
                        <Button type="primary" onClick={openPopup}>
                          <span>
                            <CloudUploadOutlined /> Update Profile
                          </span>
                        </Button>
                      )}
                    </div>
                    <UpdateProfile
                      isOpen={isPopupOpen}
                      onClose={closePopup}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="card mb-4 mb-lg-0">
                <div className="card-body p-0">
                  <ul className="list-group list-group-flush rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i className="fas fa-globe fa-lg text-warning" />
                      <p className="mb-0">https://mdbootstrap.com</p>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        className="fab fa-github fa-lg"
                        style={{ color: "#333333" }}
                      />
                      <p className="mb-0">mdbootstrap</p>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        className="fab fa-twitter fa-lg"
                        style={{ color: "#55acee" }}
                      />
                      <p className="mb-0">@mdbootstrap</p>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        className="fab fa-instagram fa-lg"
                        style={{ color: "#ac2bac" }}
                      />
                      <p className="mb-0">mdbootstrap</p>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        className="fab fa-facebook-f fa-lg"
                        style={{ color: "#3b5998" }}
                      />
                      <p className="mb-0">mdbootstrap</p>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{userData.fullname}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{userDataObject.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">You Are</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{userData.accountType}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Mobile</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {userData.contactNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4" style={{ color: "red" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <Alert
                            message={
                              <span>
                                Notification <BellOutlined />
                              </span>
                            }
                            type="error"
                          />
                        </Space>
                      </p>
                      <div>
                        <p>
                          All the nortification related profile will be
                          displayed over here.
                        </p>

                        <p style={{ color: "green" }}>
                          {userData?.nortification ??
                            "Students are noticing your profile stay active."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4" style={{ color: "red" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <Alert
                            message={
                              <span>
                                Shoping Partner <ShoppingCartOutlined />
                              </span>
                            }
                            type="info"
                          />
                        </Space>
                      </p>
                      <div>
                        {userData?.teachingCity === "JAMUI" ? (
                          <Card
                            bordered={false}
                            style={{
                              backgroundColor: "#f0f2f5",
                              borderRadius: "8px",
                              padding: "20px",
                              textAlign: "center",
                              boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Title
                              level={4}
                              style={{
                                color: "#1890ff",
                                marginBottom: "0.5em",
                              }}
                            >
                              Dawa Dawai Pharma
                            </Title>
                            <Text
                              style={{
                                display: "block",
                                marginBottom: "0.5em",
                              }}
                            >
                              A Premium Medical Store In City
                            </Text>
                            <Title
                              level={5}
                              style={{
                                color: "#52c41a",
                                marginTop: "0.5em",
                                marginBottom: "0.5em",
                              }}
                            >
                              Get 10% Discount On Medicine
                            </Title>
                            <Text
                              style={{
                                display: "block",
                                color: "#000",
                                fontSize: "0.9em",
                                cursor: "pointer",
                              }}
                            >
                              <a
                                href="https://maps.app.goo.gl/n8SHQRFvW4y7rEq4A"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  fontSize: "0.9em",
                                  color: "rgba(0, 0, 0, 0.45)",
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <EnvironmentOutlined
                                  style={{ marginRight: "8px" }}
                                />
                                Get Direction
                              </a>
                            </Text>
                            <Text
                              style={{
                                display: "block",
                                color: "rgba(0, 0, 0, 0.45)",
                                fontSize: "0.9em",
                              }}
                            >
                              Term's & Condition Apply
                            </Text>
                          </Card>
                        ) : (
                          <Text
                            style={{
                              textAlign: "center",
                              display: "block",
                              marginTop: "20px",
                            }}
                          >
                            No Shopping Partner as of Now
                          </Text>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardTutor;
