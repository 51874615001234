import React from "react";
import Navbar from "../Home-Comp/Navbar";
import Footer from "../Footer-Comp/Footer";

const About = () => {
  return (
    <>
      <Navbar />

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: 400 }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/about.png"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 className="mb-4">Welcome to Mobikraft Learning</h1>
              <h5 className="mb-2 text-primary">
                Your Gateway to Personalized Learning!
              </h5>

              <p className="mb-4">
                At <b>Mobikraft Learning</b>, we believe every student is
                unique, with their own pace and style of learning. That's why
                we're dedicated to providing personalized, one-on-one tutoring
                that caters to the individual needs of each student.
              </p>
              <p className="mb-4">
                Our tutors are more than just teachers; they are mentors and
                guides who strive to unlock the full potential of every learner.
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Skilled Instructors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Customized Learning Plans
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Interactive Online Platform
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Flexible Scheduling
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Expert Tutors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Support Beyond Academics
                  </p>
                </div>
                <br />
                <p className="mb-4 mt-4">
                  Our tutors are more than just teachers; they are mentors and
                  guides who strive to unlock the full potential of every
                  learner.
                  <b>Mobikraft Learning</b> is the educational wing of{" "}
                  <b>Mobikraft Tech PVT LTD</b> (CIN: U72900BR2021PTC055467),
                  illustrating our commitment to educational excellence and
                  innovation.
                </p>
                <p className="mb-2">
                  Managed and operated by <b>Mobikraft Tech Pvt Ltd</b>, our
                  platform ensures that every aspect of the learning experience
                  is seamless, accessible, and enriching for students and
                  parents alike.
                </p>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="/find-tutor">
                Find Tutor
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}

      <Footer />
    </>
  );
};

export default About;
