import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Image, Modal, Card, Spin, Tooltip } from "antd";
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import "./TutorProfile.css";
import Navbar from "../../V1/Home-Comp/Navbar";
import { db } from "../../../Config/FirebaseConfig"; // Import your Firebase Firestore instance
import { collection, getDocs } from "firebase/firestore";
import {
  ClassYouCanTeach,
  RanchiArea,
  PatnaArea,
  JamuiArea,
  DeogharArea,
  BhagalpurArea,
  TeachingCity,
  Teaching_Exp,
  SubjectCombination,
  Nortification_msg_Find_Tutor,
} from "./Dashboard-Tutor/Constant";
import CustomModal from "../CustomPopUp/CustomModal";
import { Pagination } from "antd";
import Footer from "../../V1/Footer-Comp/Footer";
import NeedHelpIcon from "./Dashboard-Tutor/NeedHelpIcon";
import imageLogo from "../../../Common/Img/ml_logo.png";

const TutorProfileCard = ({
  teachingCity,
  email,
  id,
  profilePic,
  fullname,
  ClassYouCanTeach,
  SubjectCombination,
  AnyTeachingExperience,
  cityLocation,
  MediumOfTeaching,
  handleGetMobileNoClick,
  contactNumber,
  openImageModal,
}) => {
  return (
    <Card className="tutor-profile-card">
      <div className="tutor-image-container">
        <EyeOutlined
          className="overlay-icon"
          onClick={() => openImageModal(profilePic)}
        />
        <img
          src={profilePic}
          alt={fullname}
          className="tutor-image"
          onClick={() => openImageModal(profilePic)}
        />{" "}
        <div className="teaching-city">
          <EnvironmentOutlined style={{ marginRight: "8px" }} />
          <b>{teachingCity}</b>
        </div>
      </div>
      <div className="tutor-details">
        <h5 className="tutor-name">{fullname?.toUpperCase()}</h5>
        <p className="tutor-info">
          <b>Experience:</b> {AnyTeachingExperience}
        </p>
        <p className="tutor-info">
          <b>Medium:</b> {MediumOfTeaching}
        </p>
        <p className="tutor-info">
          <b>Can Teach Classes:</b> {ClassYouCanTeach?.join(", ")}
        </p>
        <p className="tutor-info">
          <b>Subjects:</b> {SubjectCombination?.join(", ")}
        </p>
        <p className="tutor-info">
          <button
            className="contact-btn"
            onClick={() =>
              handleGetMobileNoClick(
                contactNumber,
                email,
                fullname,
                id,
                teachingCity
              )
            }
          >
            Get Mobile No
          </button>
        </p>
        <p className="tutor-info">
          <b>City Location: </b> {cityLocation?.join(", ") ?? []}
        </p>
      </div>
    </Card>
  );
};

// main components

const TutorProfile = () => {
  const [alltutors, setAlltutors] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [isfilter, setIsfilter] = useState(false);
  const [filterClass, setFilterClass] = useState("");
  const [selectedTeachingExp, setSelectedTeachingExp] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tutorsPerPage] = useState(8);
  const indexOfLastTutor = currentPage * tutorsPerPage;
  const indexOfFirstTutor = indexOfLastTutor - tutorsPerPage;
  const currentTutors = tutors.slice(indexOfFirstTutor, indexOfLastTutor);
  const [selectedTutor, setSelectedTutor] = useState({
    contact: "",
    email: "",
    name: "",
    id: "",
  });

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");

  const [isLoading, setIsLoading] = useState(true); // New state for loading

  const openImageModal = (imageUrl) => {
    setImageModalUrl(imageUrl);
    setImageModalVisible(true);
  };

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleGetMobileNoClick = (contactNumber, email, fullname, id, city) => {
    setSelectedTutor({
      contact: contactNumber,
      email: email,
      name: fullname,
      id: id,
      city: city,
    });
    setOpen(true);
  };
  const renderPagination = () => {
    return (
      <Pagination
        className="pagination-container"
        current={currentPage}
        total={tutors.length}
        pageSize={tutorsPerPage}
        onChange={(page) => setCurrentPage(page)}
        showSizeChanger={false} // Set to true if you want to allow changing page size
      />
    );
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    setIsLoading(true); // Start loading

    const usersCollectionRef = collection(db, "users");

    try {
      const querySnapshot = await getDocs(usersCollectionRef);
      const usersData = [];

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.accountType === "Tutor" && userData.isVerified === true) {
          usersData.push({ id: doc.id, ...userData });
        }
      });

      setAlltutors(usersData);
      setTutors(usersData); // Also set visible tutors
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching users data: ", error);
    }
  };

  const emailList = alltutors?.map((item) => item.email);

  // console.log(emailList, "Email list");

  const handleFilter = () => {
    const filteredTutors = alltutors?.filter((tutor) => {
      const matchesCity =
        selectedCity === "" || tutor?.teachingCity === selectedCity;

      const matchesArea =
        selectedArea === "" ||
        (Array.isArray(tutor?.cityLocation) &&
          tutor?.cityLocation.includes(selectedArea));

      const matchesSubjects =
        selectedSubject === "" ||
        (tutor?.SubjectCombination &&
          tutor?.SubjectCombination.includes(selectedSubject));

      const matchesExperience =
        selectedTeachingExp === "" ||
        (tutor?.AnyTeachingExperience &&
          tutor?.AnyTeachingExperience.includes(selectedTeachingExp));

      const matchesClass =
        filterClass === "" ||
        (tutor?.ClassYouCanTeach &&
          tutor?.ClassYouCanTeach.includes(filterClass));

      return (
        matchesCity &&
        matchesArea &&
        matchesSubjects &&
        matchesExperience &&
        matchesClass
      );
    });
    setIsfilter(true);
    setTutors(filteredTutors);
  };

  const handleResetFilter = () => {
    setSelectedSubject("");
    setSelectedTeachingExp("");
    setFilterClass("");
    setSelectedArea("");
    setSelectedCity("");
    fetchAllUsers(); // Fetch all users again
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(tutors.length / tutorsPerPage); i++) {
    pageNumbers.push(i);
  }

  const cityToAreaMap = {
    BHAGALPUR: BhagalpurArea,
    DEOGHAR: DeogharArea,
    JAMUI: JamuiArea,
    PATNA: PatnaArea,
    RANCHI: RanchiArea,
  };

  return (
    <>
      <Helmet>
        <title>Find Expert Tutors in City | Mobikraft Learning</title>
        <meta
          name="description"
          content="Discover Home Tuition, Home Tutor, Best Teacher, and connect with certified tutors from top cities like Deoghar, Bhagalpur, Ranchi, Jamui, and Patna. Find a tutor for Math, English, Biology, Physics, and more."
        />
        <meta
          name="keywords"
          content="Home Tuition, Home Tutor, Best Teacher, class 9, class 10, class 11, class 12, Tutors, Deoghar, Bhagalpur, Ranchi, Jamui, Patna, Math, English, Biology, Physics"
        />
        <meta
          name="description"
          content="Explore the profiles of experienced tutors in Deoghar, Bhagalpur, Ranchi, Jamui, and Patna with Mobikraft Learning. Find specialized tutors for Math, English, Science, and more for all academic levels."
        />
        <meta
          name="keywords"
          content="experienced tutors, expert tutors, city tutors, Deoghar tuition, Bhagalpur home tutor, Ranchi private teacher, Jamui education, Patna academic support, Math tutor, English teacher, Science tuition, personalized learning"
        />

        <meta property="og:title" content="Find Expert Tutors in City" />
        <meta
          property="og:description"
          content="Explore the profiles of experienced tutors in Deoghar, Bhagalpur, Ranchi, Jamui, and Patna with Mobikraft Learning. Find specialized tutors for Math, English, Science, and more for all academic levels."
        />
        <meta property="og:image" content={imageLogo} />
        <meta property="og:url" content="www.mobikraftlearning.in" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />
      <br />
      <div className="filter-container">
        <div>
          <select
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              setSelectedArea(""); // Reset area when city changes
            }}
          >
            <option value="">Select a City</option>
            {TeachingCity.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={selectedArea}
            onChange={(e) => setSelectedArea(e.target.value)}
            disabled={!selectedCity} // Disable area dropdown if no city is selected
          >
            <option value="">Select an Area</option>
            {selectedCity &&
              cityToAreaMap[selectedCity]?.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
          </select>
        </div>

        <div>
          <select
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">Filter by Subject</option>
            {SubjectCombination.map((subject, index) => (
              <option key={index} value={subject}>
                {subject}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={selectedTeachingExp}
            onChange={(e) => setSelectedTeachingExp(e.target.value)}
          >
            <option value="">Filter by Teaching Experience</option>
            {Teaching_Exp.map((exp, index) => (
              <option key={index} value={exp}>
                {exp}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={filterClass}
            onChange={(e) => setFilterClass(e.target.value)}
          >
            <option value="">Filter by Class</option>
            {ClassYouCanTeach.map((classlist, index) => (
              <option key={index} value={classlist}>
                {classlist}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button className="btn btn-primary w-100" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>
        <div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleResetFilter}
          >
            Reset Filters
          </button>
        </div>
      </div>
      <br />

      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ) : tutors && tutors.length > 0 ? (
        <div>
          <div className="tutor-cards-container">
            {currentTutors.map((tutor, index) => (
              <TutorProfileCard
                key={index}
                {...tutor}
                handleGetMobileNoClick={handleGetMobileNoClick}
                openImageModal={openImageModal}
              />
            ))}
          </div>
          {renderPagination()}
        </div>
      ) : (
        <div className="no-tutors-message">No Tutor Found</div>
      )}

      {/* model for message */}
      <CustomModal
        visible={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contactNumber={selectedTutor.contact} // Pass selected contact number as prop
        tutoremail={selectedTutor.email}
        tutorname={selectedTutor.name}
        tutorId={selectedTutor.id}
        tutorCity={selectedTutor.city}
      />
      <Modal
        visible={imageModalVisible}
        onCancel={() => setImageModalVisible(false)}
        footer={null}
      >
        <Image src={imageModalUrl} />
      </Modal>

      {/* need help icon */}

      <NeedHelpIcon />

      {/* footer part */}

      <Footer />
    </>
  );
};

export default TutorProfile;
