import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import brandLogo from "../../../Common/Img/ml_logo.png";

const Navbar = () => {
  const location = useLocation();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
  const userName = localStorage.getItem("user");
  const firstName = userName?.email;
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">
            <Link className="navbar-brand mt-2 mt-lg-0" to="/">
              <img
                src={brandLogo}
                alt="Brand Logo"
                className="pt-1"
                style={{ height: "3.0rem" }}
              />
            </Link>
          </h2>
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          onClick={toggleNavbar} // Use onClick to toggle the navbar
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link
              className={`nav-item nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
              to="/"
            >
              Home
            </Link>

            <Link
              className={`nav-item nav-link ${
                location.pathname === "/find-tutor" ? "active" : ""
              }`}
              to="/find-tutor"
            >
              Find Tutor
            </Link>
            <Link
              className={`nav-item nav-link ${
                location.pathname === "/about-us" ? "active" : ""
              }`}
              to="/about-us"
            >
              About
            </Link>
            <Link
              className={`nav-item nav-link ${
                location.pathname === "/code-nuture" ? "active" : ""
              }`}
              to="/code-nuture"
            >
              Coding Courses
            </Link>
            <Link
              className={`nav-item nav-link ${
                location.pathname === "/contact-us" ? "active" : ""
              }`}
              to="/contact-us"
            >
              Contact
            </Link>
            <div>
              {storedIsLoggedIn ? (
                <Link
                  className={`nav-item nav-link ${
                    location.pathname === "/user-dashboard" ? "active" : ""
                  }`}
                  to="/user-dashboard"
                >
                  <span
                    style={{
                      color: "#050203",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    Hi !
                  </span>{" "}
                  <span
                    style={{
                      color: "#dc3545",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {firstName}
                  </span>
                </Link>
              ) : (
                <Link
                  className={`nav-item nav-link ${
                    location.pathname === "/sign-in" ? "active" : ""
                  }`}
                  to="/sign-in"
                >
                  Sign-in
                </Link>
              )}
            </div>
          </div>
          {!userName && (
            <Link className="btn btn-primary py-4 px-lg-3" to="/register">
              Join Now
              <i className="fa fa-arrow-right ms-3" />
            </Link>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
