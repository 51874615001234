import React, { useEffect, useState } from "react";
import "./App.css";
import ClientRoutes from "./ClientRoutes";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./Components/V1/Home-Comp/Home";
import TutorProfile from "./Components/V2/Tutor-Profile/TutorProfile";
import About from "./Components/V1/About-Comp/About";
import NotFound from "./Components/V1/Not-Found-Comp/NotFound";
import Contact from "./Components/V1/ContactUs-Comp/Contact";
import SignInForm from "./Components/Sign-In-Comp/SignIn";
import RegisterForm from "./Components/Sign-In-Comp/Register-User/Register";
import DashboardTutor from "./Components/V2/Tutor-Profile/Dashboard-Tutor/DashboardTutor";
import Verify from "./Components/V1/Verify-Page/Verify";

import AdminDashboard from "./Components/V2/Tutor-Profile/Dashboard-Tutor/AdminDashboard";
import CertificateVerify from "./Components/V2/CertifateVerify/CertificateVerify";
import ResetPassword from "./Components/Sign-In-Comp/ResetPassword";
import TutorPersonalDetail from "./Components/V2/Tutor-Profile/TutorPersonalDetails";
import VerifyPage from "./Components/V2/verification-page/VerifyPage";
import AdmissionForm from "./Components/V1/Libs-Admission/AdmissionForm";
import PrivacyPolicy from "./Config/outerPage/PrivacyPolicy";
import TermsAndConditions from "./Config/outerPage/TermsAndCondition";

function App() {
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
  const userDataFromLocalStorage = localStorage.getItem("user");
  const userDataObject = JSON.parse(userDataFromLocalStorage);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path={ClientRoutes.Home.path}
            element={
              <>
                <Home />
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.ResetPassword.path}
            element={
              <>
                <ResetPassword />
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.FindTutor.path}
            element={
              <>
                <TutorProfile />
              </>
            }
          />
          <Route
            exact
            path={ClientRoutes.About.path}
            element={
              <>
                <About />
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.ContactUs.path}
            element={
              <>
                <Contact />
              </>
            }
          />
          {/* <Route
            exact
            path={ClientRoutes.TutorPersonalProfile.path}
            element={<TutorPersonalDetail/>}
          /> */}

          <Route
            exact
            path={ClientRoutes.SignIn.path}
            element={
              storedIsLoggedIn ? (
                <Navigate to={ClientRoutes.TutorDashboard.path} />
              ) : (
                <SignInForm />
              )
            }
          />
          <Route
            exact
            path={ClientRoutes.Register.path}
            element={
              <>
                <RegisterForm />
              </>
            }
          />
          {userDataObject?.email === "nileshsinghwork001@gmail.com" && (
            <Route
              exact
              path={ClientRoutes.AdminDashboard.path}
              element={
                <>
                  <AdminDashboard />
                </>
              }
            />
          )}

          <Route
            exact
            path={ClientRoutes.VerifyPage.path}
            element={
              <>
                <VerifyPage />
              </>
            }
          />
          <Route
            exact
            path={ClientRoutes.UserVerify.path}
            element={
              <>
                <Verify />
              </>
            }
          />
          <Route
            exact
            path={ClientRoutes.PrivacyPolicy.path}
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.TermsAndCondition.path}
            element={
              <>
                <TermsAndConditions/>
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.LibsAdmission.path}
            element={
              <>
                <AdmissionForm />
              </>
            }
          />

          <Route
            exact
            path={ClientRoutes.TutorDashboard.path}
            element={
              <>
                {storedIsLoggedIn ? (
                  <DashboardTutor />
                ) : (
                  // Redirect to SignIn if not logged in
                  <Navigate to={ClientRoutes.SignIn.path} />
                )}
              </>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
