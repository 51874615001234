import React from "react";
import "./Seat.css"; // Make sure this points to the correct CSS/SCSS file

const Seat = ({ id, isSelected, onToggle }) => {
  // Extracting the numeric part if the id is in a specific format e.g., "seat-0"
  const seatNumber = parseInt(id.replace(/^\D+/g, ""), 10) + 1; // This removes non-digit characters from the start of the string and adds 1

  return (
    <div
      className={`seat ${isSelected ? "selected" : ""}`}
      onClick={() => onToggle(id)}
    >
      {seatNumber} {/* Displaying the seat number here, starting from 1 */}
    </div>
  );
};

export default Seat;
