const ClientRoutes = {
  Home: { path: "/" },
  Login: { patch: "/login" },
  Register: { path: "/register" },
  FindTutor: { path: "/find-tutor" },
  About: { path: "/about-us" },
  AdminDashboard: { path: "/admin-dashboard" },
  UserVerify: { path: "/verify/:id" },
  // CertificateVerify: { path: "/verify" },
  LibsAdmission:{path:"/libs-admission"},
  NotFound: { path: "/page-not-found" },
  SignIn: { path: "/sign-in" },
  ContactUs: { path: "/contact-us" },
  TutorDashboard: { path: "/user-dashboard" },
  PrivacyPolicy: { path: "/privacy-policy" },
  ResetPassword: { path: "/reset-password" },
  TutorPersonalProfile: { path: "/tutors-details/:tutorId" },
  PrivacyPolicyEu: { path: "/privacy-notice-for-eu-residents" },
  TermsAndCondition: { path: "/terms-and-condition" },
  CopyRightPolicy: { path: "/copyright-policy" },
  WebsiteTermsOfUse: { path: "/website-terms-of-use" },
  LegalDesclaimers: { path: "/legal-disclaimers" },
  VerifyPage: { path: "/verify" },
};

export default ClientRoutes;
