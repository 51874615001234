import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const Footer = () => {
  const [email_id, setEmail_id] = useState("");
  const [signupMessage, setSignupMessage] = useState("");

  const handleSignup = async () => {
    try {
      const response = await axios.post(
        "https://api-mobikraft.onrender.com/api/sendInvite",
        {
          email_id,
        }
      );

      if (response.status === 201) {
        setSignupMessage("Successfully subscribed!");
        setEmail_id("");
      } else {
        setSignupMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      setSignupMessage("Subscription failed. Please try again.");
    }
  };
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Quick Link</h4>

              <Link className="btn btn-link" to="/verify">
                Verify Certificate
              </Link>
              <Link className="btn btn-link" to="/contact-us">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="btn btn-link" to="/terms-and-condition">
                Terms &amp; Condition
              </Link>
              <Link className="btn btn-link" to="/faqs-help">
                FAQs &amp; Help
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                2nd Floor Building No-354, Neeladari Nagar, EC-1, Bangalore, KA,
                560100
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                8809-7777-98
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-2" />
                mobikraftlearning@gmail.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube" />
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Gallery</h4>
              <div className="row g-2 pt-2">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-1.png"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-2.png"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-3.png"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-4.png"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-5.png"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-6.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>
                Get subscribed to our new offers and plans. Stay early and stay
                happy!
              </p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: "400px" }}
              >
                {/* <input
                  className="form-control border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                  value={email_id}
                  onChange={(e) => setEmail_id(e.target.value)}
                  style={{ borderRadius: "20px", fontSize: "1rem" }}
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                  onClick={handleSignup}
                  style={{ borderRadius: "20px", fontSize: "1rem" }}
                >
                  Subscribe
                </button> */}
              </div>
              <p className="mt-2">{signupMessage}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                <a className="border-bottom" href="/admin-dashboard">
                  Mobikraft Learning
                </a>
                , All Right Reserved.
                {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                Designed By{" "}
                <a className="border-bottom" href="https://mobikraft.in/">
                  Mobikraft Tech PVT LTD
                </a>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <a href="">Home</a>
                  <a href="">Cookies</a>
                  <a href="">Help</a>
                  <a href="">FQAs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
