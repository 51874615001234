import React, { useState } from "react";
import { Input, Button, Spin, Form, message } from "antd";
import "./offerLetter.css"; // Make sure the path matches your file structure
import { db } from "../../../Config/FirebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";

const OfferLetter = () => {
  const [offerLetterId, setOfferLetterId] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false); // New state to track if search has been performed

  const verifyOfferLetter = async () => {
    if (!offerLetterId.trim()) {
      message.error("Please enter a valid Offer Letter ID.");
      return; // Prevents verification if input is empty or only whitespace
    }
    setLoading(true);
    setSearchPerformed(true); // Set to true as search is being performed
    await fetchOfferLetterInfo(offerLetterId);
    setLoading(false);
  };

  const fetchOfferLetterInfo = async (offerLetterId) => {
    const offerDataRef = collection(db, "offerletterData");
    const q = query(
      offerDataRef,
      where("offerletter_id", "==", offerLetterId.toUpperCase())
    );

    try {
      const querySnapshot = await getDocs(q);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      if (data.length > 0) {
        setOfferData(data);
        message.success("Data found");
      } else {
        setOfferData([]);
        message.error("No data found");
      }
    } catch (error) {
      console.error("Error fetching offer letter data: ", error);
      message.error("Failed to fetch data.");
      setOfferData([]); // Ensure offerData is cleared on error
    }
  };

  return (
    <div className="offer-letter-container">
      <div className="input-button-container">
        <Form.Item
          label="Offer Letter ID"
          required
          tooltip="This is a required field"
          className="form-item-custom"
        >
          <Input
            placeholder="Enter Offer Letter ID"
            value={offerLetterId}
            onChange={(e) => setOfferLetterId(e.target.value)}
            disabled={loading}
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={verifyOfferLetter}
          disabled={!offerLetterId.trim() || loading}
          className="verify-button"
        >
          {loading ? <Spin /> : "Verify"}
        </Button>
      </div>
      <div className="result">
        {!loading && searchPerformed && offerData.length === 0 ? (
          <p className="text-danger">No data found</p>
        ) : (
          offerData.map((data) => (
            <div key={data.id}>
              <p>
                <strong>Offer Letter ID:</strong> {data.offerletter_id}
              </p>
              <p>
                <strong>Name:</strong> {data.name}
              </p>
              <p>
                <strong>College:</strong> {data.college}
              </p>
              <p>
                <strong>Mobile Number:</strong> {data.mobile}
              </p>
              <p>
                <strong>Offer Letter Position:</strong> {data.position}
              </p>
              <p>
                <strong>Offer Letter Date:</strong> {data.offerdate}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OfferLetter;
