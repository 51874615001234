import React, { useState } from "react";
import { Form, Input, Button } from "antd";

import Navbar from "../V1/Home-Comp/Navbar";
import Footer from "../V1/Footer-Comp/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Config/FirebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { API_BASE_URL } from "../../Config/url";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    width: "100%",
  };

  const handleLogin = async (values) => {
    const { email, password } = values;
    // e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      if (user.emailVerified) {
        // Email is verified, allow the user to sign in
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("user", JSON.stringify(user));
        // Redirect to the user's dashboard or another page upon successful sign-in
        window.location.href = "/user-dashboard";
      } else {
        // Email is not verified, show an error message
        setErrorMessage("Please verify your email address before logging in.");
      }
    } catch (error) {
      // Handle errors here and set custom error messages
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error code:", errorCode);
      console.error("Error message:", errorMessage);
      setErrorMessage("Invalid email or password. Please try again.");
    }

    setLoading(true);
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card shadow">
              <div className="card-body text-center m-4">
                <h4>Log In</h4>
                <p className="mb-3">Enter your email and password below</p>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
              <Form
                onFinish={handleLogin} // Ant Design handles the submit event with onFinish
                className="px-4 pb-4 pt-0"
              >
                <Form.Item
                  name="email"
                  label="Email id"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item>
                  <a href="/reset-password">Forgot Password</a>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={buttonStyle}
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Link to="/register">New User!</Link>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignInForm;
