import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../Config/url";

const Verify = () => {
  const [verifyStatus, setVerifyStatus] = useState("");
  const location = useLocation();

  // Extract the id from the URL path (assuming it's the last segment)
  const urlSegments = location.pathname.split("/");
  const idParam = urlSegments[urlSegments.length - 1];
  console.log(idParam, "id param");
  console.log(verifyStatus, "id verifyStatus");
  useEffect(() => {
    if (idParam) {
      axios
        .get(`${API_BASE_URL}/api/verify/${idParam}`)
        .then((response) => {
          setVerifyStatus(response.data); // Assuming that the response contains the verification status
        })
        .catch((error) => {
          console.error("Error fetching program details:", error);
        });
    }
  }, [idParam]);

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="card text-center" style={{ width: "400px" }}>
          <div className="card-body">
            <h5 className="card-title">Your {verifyStatus?.msg}</h5>
            <p className="card-text">
              Please click here to login to your account.
            </p>
            <a href="/sign-in" className="btn btn-primary">
              Login
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
