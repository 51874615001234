import React, { useState } from "react";
import UpdateCertificate from "./UpdateCertificate";
import AllProfile from "./AllProfile";
import { Menu, Row, Col } from "antd";
import {
  UsergroupAddOutlined,
  MessageOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import InteractionsAdminView from "./InteractionAdminView";

const items = [
  {
    label: "All Profile",
    key: "content",
    icon: <UsergroupAddOutlined />,
  },
  {
    label: "Interaction",
    key: "styling",
    icon: <MessageOutlined />,
  },
  {
    label: "Certificate",
    key: "layout",
    icon: <FileImageOutlined/>,
  },
 
 
];

const AdminDashboard = () => {
  const [current, setCurrent] = useState("content");

  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <Row justify="center" style={{ margin: "2rem 0" }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={14}>
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
            style={{ borderBottom: "2px solid #f0f0f5", marginBottom: "2rem" }}
          />
          {current === "layout" && <UpdateCertificate />}
          {current === "content" && <AllProfile />}
          {current === "styling" && <InteractionsAdminView/>}

        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
