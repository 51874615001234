import React, { useState, useEffect } from "react";
import { db } from "../../../../Config/FirebaseConfig";
import {
  doc,
  collection,
  getDocs,
  setDoc,
  orderBy,
  query,
} from "firebase/firestore";
import emailjs from "@emailjs/browser";

import {
  ref, // Add this import
  uploadBytes, // Add this import
  getDownloadURL, // Add this import
} from "firebase/storage";
import { deleteDoc } from "firebase/firestore";

import { getStorage } from "firebase/storage";

import {
  Table,
  Button,
  Modal,
  Image,
  Input,
  Select,
  Switch,
  Popconfirm,
} from "antd";
import { EyeOutlined, MessageOutlined } from "@ant-design/icons";
import {
  TeachingCity,
  BhagalpurArea,
  RanchiArea,
  PatnaArea,
  JamuiArea,
  DeogharArea,
  SubjectCombination,
  ClassYouCanTeach,
  Teaching_Exp,
  PostGrad_Degree,
  Grad_Degree,
  Class12Board,
  Class10Board,
  Teaching_Medium,
  tutorEmail_List,
} from "./Constant";

const AllProfile = () => {
  const cityToAreaMap = {
    BHAGALPUR: BhagalpurArea,
    DEOGHAR: DeogharArea,
    JAMUI: JamuiArea,
    PATNA: PatnaArea,
    RANCHI: RanchiArea,
    // ... any other mappings
  };

  // State to hold profiles data

  const storage = getStorage();

  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [profilesPerPage] = useState(10);
  const [editingId, setEditingId] = useState(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");
  const [filter, setFilter] = useState("All");
  const openImageModal = (imageUrl) => {
    setImageModalUrl(imageUrl);
    setImageModalVisible(true);
  };
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchAllProfile();
  }, []);

  const fetchAllProfile = async () => {
    const usersCollectionRef = collection(db, "users");

    try {
      const q = query(usersCollectionRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const usersData = [];

      querySnapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() });
      });

      setProfiles(usersData);
    } catch (error) {
      console.error("Error fetching and sorting users data: ", error);
    }
  };

  console.log(profiles, "all");

  const filteredProfiles = profiles.filter((profile) => {
    const searchLower = searchText.toLowerCase();
    return (
      profile.contactNumber?.toString().toLowerCase().includes(searchLower) ||
      profile.email?.toLowerCase().includes(searchLower)
    );
  });

  const sendEmailToAll = (tutorEmail_List) => {
    tutorEmail_List.forEach((email) => {
      const templateParams = {
        // from_name: formData.userName,
        // message: `Name: ${formData.userName}, Mobile No: ${formData.userMobile}, Email: ${formData.userEmail}`,
        to_email: email, // Use the email from the array
        // tutor_name: tutorname, // Include this if you have the name associated with each email
      };

      emailjs
        .send(
          "service_46urz8q",
          "template_hcf680e",
          templateParams,
          "SwFwSYJqiBhK3nLEx"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            // You may want to handle success for each email separately
          },
          (err) => {
            console.error("FAILED...", err);
            // You may want to handle failure for each email separately
          }
        );
    });
  };

  // Usage

  const sendEmailAll = () => {
    sendEmailToAll(tutorEmail_List);
  };

  // Button click handlers to change the filter
  const handleAllProfilesClick = () => {
    setFilter("All");
  };

  const handleVerifiedProfilesClick = () => {
    setFilter("Verified");
  };
  const handleUnVerifiedProfilesClick = () => {
    setFilter("Unverified");
  };

  useEffect(() => {
    if (filter === "All") {
      fetchAllProfile();
    } else {
      const filteredProfiles = profiles.filter(
        (profile) => !profile.isVerified === (filter === "Unverified")
      );
      setProfiles(filteredProfiles);
    }
  }, [filter]);

  const handleEdit = (profileId) => {
    setEditingId(profileId);
  };

  const handleChange = (e, id, key) => {
    // Determine if 'e' is an event object or directly the value(s) from Select
    const value = e?.target ? e.target.value : e;

    const newData = profiles.map((profile) => {
      if (profile.id === id) {
        return { ...profile, [key]: value };
      }
      return profile;
    });
    setProfiles(newData);
  };

  // Handle Delete Click - implement the logic
  const handleDelete = async (id) => {
    try {
      // Delete the profile from Firestore
      await deleteDoc(doc(db, "users", id));

      // Update local state to reflect deletion
      const updatedProfiles = profiles.filter((profile) => profile.id !== id);
      setProfiles(updatedProfiles);

      console.log(`Profile with id ${id} deleted successfully`);
    } catch (error) {
      console.error("Error deleting profile: ", error);
    }
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // console.log(profiles, "Newipp");
  const handleSave = async (profileId) => {
    const updatedProfile = profiles.find((profile) => profile.id === profileId);

    if (!updatedProfile) {
      console.error("Profile not found for editing:", profileId);
      return;
    }

    try {
      // Update the profile in your database
      const userDocRef = doc(db, "users", profileId);
      await setDoc(userDocRef, updatedProfile, { merge: true });

      // Refresh the profiles list
      fetchAllProfile();

      // Exit editing mode
      setEditingId(null);
    } catch (error) {
      console.error("Error updating profile: ", error);
      // Handle errors (e.g., show a notification to the user)
    }
  };

  const handleIdProofChange = async (e, profileId) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const storageRef = ref(
      storage,
      `idProofs/${profileId}/${file.name}_${Date.now()}`
    );

    try {
      const uploadTaskSnapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

      // Update the profile in Firestore with the new ID proof URL
      const userDocRef = doc(db, "users", profileId);
      await setDoc(userDocRef, { idProof: downloadURL }, { merge: true });

      // Update local state to reflect changes
      const updatedProfiles = profiles.map((profile) => {
        if (profile.id === profileId) {
          return { ...profile, idProof: downloadURL };
        }
        return profile;
      });
      setProfiles(updatedProfiles);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const handleProfilePicChange = async (e, profileId) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const storageRef = ref(
      storage,
      `profilePictures/${profileId}/${file.name}_${Date.now()}`
    );

    try {
      const uploadTaskSnapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

      // Update the profile in Firestore with the new profile picture URL
      const userDocRef = doc(db, "users", profileId);
      await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true });

      // Update local state to reflect changes
      const updatedProfiles = profiles.map((profile) => {
        if (profile.id === profileId) {
          return { ...profile, profilePic: downloadURL };
        }
        return profile;
      });
      setProfiles(updatedProfiles);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const tutorsInfo = profiles
    .filter((tutor) => tutor.teachingCity === "RANCHI" && tutor.isVerified)
    .map((tutor) => ({
      email: tutor.email,
      fullname: tutor.fullname,
      contactNumber: tutor.contactNumber,
    }));

  // console.log(tutorsInfo, "Ranchi Tutor");
  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profilePic",
      key: "profilePic",
      render: (text, record) => (
        <>
          <div onClick={() => openImageModal(text)}>
            <Image
              src={text || "default-profile-pic-url"} // Replace "default-profile-pic-url" with an actual URL if needed
              alt={record.fullname}
              style={{
                width: 50,
                height: 50,
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </div>
          <input
            type="file"
            accept="image/*"
            style={{ display: "block", marginTop: "10px" }}
            onChange={(e) => handleProfilePicChange(e, record.id)}
          />
        </>
      ),
    },
    {
      title: "Id Proof",
      dataIndex: "idProof",
      key: "idProof",
      render: (text, record) => (
        <>
          {text ? (
            <div onClick={() => openImageModal(text)}>
              <Button icon={<EyeOutlined />} />
            </div>
          ) : (
            "N/A"
          )}
          <input
            type="file"
            accept="image/*,application/pdf"
            style={{ display: "block", marginTop: "10px" }}
            onChange={(e) => handleIdProofChange(e, record.id)}
          />
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
      render: (text, record) => {
        return editingId === record.id ? (
          <Input
            style={{ width: "100px" }}
            defaultValue={text}
            onChange={(e) => handleChange(e, record.id, "fullname")}
          />
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "WhatsApp",
      dataIndex: "contactNumber",
      key: "contactNumber",
      render: (text, record) => {
        // Determine the phone number to use for the WhatsApp link
        const whatsAppLink = `https://wa.me/91${record.whatsAppNo || text}`;

        return (
          <a href={whatsAppLink} target="_blank" rel="noopener noreferrer">
            <MessageOutlined style={{ marginRight: 8, fontSize: "16px" }} />
            {record.whatsAppNo ? record.whatsAppNo : text}
          </a>
        );
      },
    },
    {
      title: "User Type",
      dataIndex: "accountType",
      key: "accountType",
      render: (text, record) => {
        return editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "accountType")
            }
          >
            <Select.Option value="Tutor">Tutor</Select.Option>
            <Select.Option value="Student">Student</Select.Option>
            {/* Add more options as needed */}
          </Select>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) =>
        editingId === record.id ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleChange(e, record.id, "email")}
          />
        ) : (
          <span>{text}</span>
        ),
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text, record) =>
        editingId === record.id ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleChange(e, record.id, "gender")}
          />
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Classes to Teach",
      dataIndex: "ClassYouCanTeach",
      key: "ClassYouCanTeach",
      render: (classes, record) => {
        return editingId === record.id ? (
          <Select
            mode="multiple"
            defaultValue={classes || []}
            style={{ width: "100%" }}
            onChange={(selectedClasses) =>
              handleChange(selectedClasses, record.id, "ClassYouCanTeach")
            }
          >
            {ClassYouCanTeach.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{classes?.join(", ")}</span>
        );
      },
    },
    {
      title: "Medium",
      dataIndex: "MediumOfTeaching",
      key: "MediumOfTeaching",
      render: (text, record) => {
        return editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: 120 }}
            onChange={(value) =>
              handleChange(
                { target: { value: value } },
                record.id,
                "MediumOfTeaching"
              )
            }
          >
            {Teaching_Medium.map((medium) => (
              <Select.Option key={medium} value={medium}>
                {medium}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "Subject Combination",
      dataIndex: "SubjectCombination",
      key: "SubjectCombination",
      render: (subjects, record) => {
        return editingId === record.id ? (
          <Select
            mode="multiple"
            defaultValue={subjects || []}
            style={{ width: "100%" }}
            onChange={(selectedClasses) =>
              handleChange(selectedClasses, record.id, "SubjectCombination")
            }
          >
            {SubjectCombination.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{subjects?.join(", ")}</span>
        );
      },
    },

    {
      title: "10th Board",
      dataIndex: "tenthBoard",
      key: "tenthBoard",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "tenthBoard")
            }
          >
            {Class10Board.map((board) => (
              <Select.Option key={board} value={board}>
                {board}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "12th Board",
      dataIndex: "twenthBoard",
      key: "twenthBoard",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "twenthBoard")
            }
          >
            {Class12Board.map((board) => (
              <Select.Option key={board} value={board}>
                {board}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Graduation",
      dataIndex: "graduation",
      key: "graduation",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "graduation")
            }
          >
            {Grad_Degree.map((degree) => (
              <Select.Option key={degree} value={degree}>
                {degree}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Master",
      dataIndex: "postGraduation",
      key: "postGraduation",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "postGraduation")
            }
          >
            {PostGrad_Degree.map((degree) => (
              <Select.Option key={degree} value={degree}>
                {degree}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Diploma",
      dataIndex: "diploma",
      key: "diploma",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "diploma")
            }
          >
            {Class12Board.map((degree) => (
              <Select.Option key={degree} value={degree}>
                {degree}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
      render: (text, record) =>
        editingId === record.id ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleChange(e, record.id, "contactNumber")}
          />
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "WhatsApp No",
      dataIndex: "whatsAppNo",
      key: "whatsAppNo",
      render: (text, record) =>
        editingId === record.id ? (
          <Input
            defaultValue={text}
            onChange={(e) => handleChange(e, record.id, "whatsAppNo")}
          />
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Teaching Experience",
      dataIndex: "AnyTeachingExperience",
      key: "AnyTeachingExperience",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange(
                { target: { value } },
                record.id,
                "AnyTeachingExperience"
              )
            }
          >
            {Teaching_Exp.map((exp) => (
              <Select.Option key={exp} value={exp}>
                {exp}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "Base City",
      dataIndex: "teachingCity",
      key: "teachingCity",
      render: (text, record) =>
        editingId === record.id ? (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { value } }, record.id, "teachingCity")
            }
          >
            {TeachingCity.map((city) => (
              <Select.Option key={city} value={city}>
                {city}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{text}</span>
        ),
    },

    {
      title: "City Location",
      dataIndex: "cityLocation",
      key: "cityLocation",
      render: (locations, record) => {
        const areas = cityToAreaMap[record.teachingCity] || [];

        return editingId === record.id && areas.length > 0 ? (
          <Select
            mode="multiple"
            defaultValue={locations}
            style={{ width: "100%" }}
            onChange={(values) =>
              handleChange(
                { target: { value: values } },
                record.id,
                "cityLocation"
              )
            }
          >
            {areas.map((area) => (
              <Select.Option key={area} value={area}>
                {area}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>{locations?.join(", ")}</span>
        );
      },
    },

    {
      title: "Verified?",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (isVerified, record) => {
        const textColor = isVerified ? "#00CC00" : "#FF6B6B"; // Green for Yes, Volcano for No
        return editingId === record.id ? (
          <Select
            defaultValue={isVerified ? "true" : "false"}
            onChange={(value) =>
              handleChange(
                { target: { value: value === "true" } },
                record.id,
                "isVerified"
              )
            }
          >
            <Select.Option value="true">Yes</Select.Option>
            <Select.Option value="false">No</Select.Option>
          </Select>
        ) : (
          <span style={{ color: textColor }}>{isVerified ? "Yes" : "No"}</span>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return editingId === record.id ? (
          <>
            <Popconfirm
              title="Are you sure you want to save?"
              onConfirm={() => handleSave(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to delete this profile?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
            <Button
              onClick={() => setEditingId(null)}
              type="default"
              style={{ marginTop: 8 }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button onClick={() => handleEdit(record.id)} type="primary">
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div className="container-fluid mt-4">
      <h3>All Profiles</h3>

      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by Mobile No or Email"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      {/* Filter buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Button
          type={filter === "All" ? "primary" : "default"}
          onClick={handleAllProfilesClick}
          style={{ marginRight: "8px" }} // Adjust the margin-right as needed
        >
          All Profiles
        </Button>

        <Button
          type={filter === "Unverified" ? "primary" : "default"}
          onClick={handleUnVerifiedProfilesClick}
          style={{ marginLeft: "8px" }} // Adjust the margin-left as needed
        >
          Un Verified Profiles
        </Button>
      </div>

      {/* Display total number of profiles */}
      <div
        style={{
          fontSize: "1.1rem",
          color: "#018749",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        <p>Total Profiles: {profiles?.length}</p>
      </div>

      <div className="table-responsive">
        <Table
          dataSource={filteredProfiles}
          columns={columns}
          rowKey="id"
          pagination={{
            total: filteredProfiles.length,
            pageSize: profilesPerPage,
            onChange: paginate,
          }}
        />
      </div>
      <Modal
        visible={imageModalVisible}
        onCancel={() => setImageModalVisible(false)}
        footer={null}
      >
        <Image src={imageModalUrl} />
      </Modal>

      <div>
        {/* <Button onClick={sendEmailAll}> Send Mail To Tutor</Button> */}
      </div>
    </div>
  );
};

export default AllProfile;
