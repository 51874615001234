import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Carousel = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    // Other options...
  };
  return (
    <>
      {/* Carousel Start */}
      <div className="container-fluid p-0 mb-5">
        <OwlCarousel className="header-carousel owl-theme" {...options}>
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src="img/carousel-1.png" alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5 className="text-primary text-uppercase mb-3 animated slideInDown">
                      Best Online Website
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      The Best Home Tutor Platform
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                      Join US today and embark on a journey of educational
                      success tailored just for you!
                    </p>
                    <a
                      href="/find-tutor"
                      className="btn btn-primary py-md-3 px-md-5 animated slideInRight"
                    >
                      Find Tutor Near By
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src="img/carousel-2.png" alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5 className="text-primary text-uppercase mb-3 animated slideInDown">
                      Flexible Opportunities.
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Join Our Community:
                    </h1>
                    <p className="fs-5 text-white mb-4 pb-2">
                      Teach on your terms. Set your schedule, choose your
                      subjects, and work as much or as little as you like
                    </p>
                    <a
                      href="/register"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add more slides */}
        </OwlCarousel>
      </div>
      {/* Carousel End */}
    </>
  );
};

export default Carousel;
