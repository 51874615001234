import React, { useState } from "react";
import { Form, Input, Button, Select, Modal } from "antd";
import "./AdmissionForm.css"; // Make sure to create this SCSS file
import SeatMap from "./SeatMap"; // Import your SeatMap component

const { Option } = Select;

const AdmissionForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    // Handle form submission here (e.g., update state, send to backend)
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // Handle actions when clicking OK in the modal, e.g., saving the selected seat
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Form
        name="admission_form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="admission-form"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mobile"
          name="mobile"
          rules={[
            { required: true, message: "Please input your mobile number!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Select Slot"
          name="slot"
          rules={[{ required: true, message: "Please select a slot!" }]}
        >
          <Select>
            <Option value="morning">Morning</Option>
            <Option value="afternoon">Afternoon</Option>
            <Option value="evening">Evening</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="default" onClick={showModal}>
            Allot Seat
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      {/* Modal for seat selection */}
      <Modal
        title="Select a Seat"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="seat-map-container">
          <SeatMap />
        </div>
      </Modal>
    </>
  );
};

export default AdmissionForm;
